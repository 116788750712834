import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import CreateCommunityPopup from "./CreateCommunityPopup";
import Loader from "../Loader/Loader";
import "./YourCommunity.css";
import { useNavigate } from "react-router-dom";
import getCategoryImages from "../CommunityPreview/CategoryImages";
import image from "../../assets/images/imageplaceholder.png";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";

type Props = {
  yourCommunityList: any;
  loader: any;
  refresh?: boolean;
  selectedCategoryId: string;
  queryText: string;
  triggerSearch?: number;
};

function YourCommunity(props: Props) {
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [communityList, setCommunityList] = useState<any>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const [communityCategory, setCommunityCategory] = useState<any[]>([]);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  let apiCount = 0;

  const navigate = useNavigate();

  const getCommunityData = async (page?: number) => {
    console.log(
      "Getting",
      props?.queryText.trim()?.length > 0 &&
        props?.selectedCategoryId.trim()?.length > 0,
      props?.queryText
    );
    const url =
      props?.queryText.trim()?.length > 0 &&
      props?.selectedCategoryId.trim()?.length > 0
        ? `/member/current/community/search?communityName=${
            props?.queryText
          }&communityCategoryId=${
            props?.selectedCategoryId !== "" ? props?.selectedCategoryId : null
          }&isMyCommunity=true`
        : `/member/current/memberships?page=${page}&size=10`;
    console.log(url);

    let requestPromise = request(url);
    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );
    apiCount++;

    try {
      const resp = await Promise.race([requestPromise, timeoutPromise]);

      console.log(resp);
      if (resp?.content?.length === 0) {
        setHasMore(false);
      }
      if (
        props?.queryText.trim()?.length > 0 &&
        props?.selectedCategoryId.trim()?.length > 0
      ) {
        setCommunityList(resp);
        return;
      }

      if (resp?.content?.length >= 0) {
        if (page === 0) {
          setCommunityList(resp?.content);
          setHasMore(true);
          cacheResponse(
            `/member/current/memberships?page=${page}&size=10`,
            resp?.content
          );
        } else {
          setCommunityList((prevCommunityList: any) => [
            ...prevCommunityList,
            ...resp?.content,
          ]);
          cacheResponse(
            `/member/current/memberships?page=${page}&size=10`,
            communityList
          );
        }
        setLoader(false);
      } else {
        // Use cached data if available
        const cachedResponse = await getCachedResponse(
          `/member/current/memberships?page=${page}&size=10`
        );

        if (cachedResponse) {
          setCommunityList(cachedResponse);
          if (apiCount < 3) getCommunityData(page);
        } else {
          console.error("Failed to get cached data");
        }
        setLoader(false);
      }
    } catch (error) {
      // Handle timeout or other errors
      console.error(error);

      setLoader(false);
    }
  };
  useEffect(() => {
    if (props?.queryText.trim()?.length === 0) {
      getCommunityData(0);
      setCurrentPage(0);
    }
  }, [props?.queryText]);

  useEffect(() => {
    console.log("props?.selectedCategoryId", props?.selectedCategoryId);
    getCommunityData(0);
  }, [props?.triggerSearch]);

  useEffect(() => {
    setCurrentPage(0);
  }, [toggleRefresh, props?.refresh]);

  useEffect(() => {
    getCommunityData(0);
  }, [props?.refresh]);

  useEffect(() => {
    getCommunityData(currentPage);
  }, [currentPage]);

  const handleRefresh = async (): Promise<void> => {
    setToggleRefresh(!toggleRefresh);
  };
  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <div style={{ minHeight: "250px" }}>
        {loader ? (
          <div className="loader mt-22">
            <Loader />{" "}
          </div>
        ) : communityList?.length > 0 ? (
          <div className="flex flex-col yourComWrap">
            {createPopup ? (
              <div>
                <CreateCommunityPopup
                  onCancelClick={() => {
                    setCreatePopup(false);
                  }}
                  onSaveClick={() => {
                    getCommunityData(0);
                  }}
                  isOpen={createPopup}
                  communityCategory={communityCategory}
                />
              </div>
            ) : (
              <div>
                <div className="infoBox">
                  {" "}
                  <p className="text-base">
                    A community is a social unit (a group of living things) with
                    commonality such as place, norms, religion, values, customs,
                    or identity. Communities may share a sense of place situated
                    in a given geographical area (e.g. a country, village, town,
                    or neighbourhood) or in virtual space through communication
                    platforms.
                  </p>
                </div>
                <div className="topPanelHead">
                  <h2 className="topPanelHeadColL headTop ">
                    Your Communities List
                  </h2>
                </div>
                <InfiniteScroll
                  dataLength={communityList?.length}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  loader={<></>}
                >
                  <div className="thumbnailBox">
                    <div className="thumbnailRowForThree communityUI">
                      {communityList?.map((community: any, index: any) => {
                        const categoryImages = getCategoryImages(
                          community?.categoryName
                        );
                        return (
                          <div
                            className={
                              "thumbnailRowForThreeColumn text-[#804C31]"
                            }
                            style={{ cursor: "pointer" }}
                            key={community?.id || index}
                            onClick={() =>
                              navigate(
                                `/communityPreview/${
                                  props?.queryText === ""
                                    ? community.communityId
                                    : community?.id
                                }`,
                                {
                                  state: {
                                    communityMemberStatus: community?.admin
                                      ? "admincommunity"
                                      : "othercommunity",
                                    from: 0,
                                  },
                                }
                              )
                            }
                          >
                            {community?.admin && (
                              <button
                                type="button"
                                className="bi bi-person-gear text-white adminIcon"
                                style={{
                                  position: "absolute",
                                  backgroundColor: "#67702E",
                                  padding: "5px 10px 10px 5px",
                                  borderBottomRightRadius: "50%",
                                }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              ></button>
                            )}
                            <div
                              className={
                                community?.communityBlocked || community?.block
                                  ? "blockedMessage thumbnailImg"
                                  : "thumbnailImg"
                              }
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "150px",
                                  objectFit: "cover",
                                }}
                                src={
                                  community?.communityBannerUrl ||
                                  categoryImages.bannerUrl
                                }
                                alt="Banner Cover"
                              />
                            </div>

                            <div
                              className="communityGridParent"
                              style={{ position: "relative", bottom: "55px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  objectFit: "cover",
                                }}
                                className="communityGrid"
                              >
                                <img
                                  src={
                                    community?.communityProfileUrl ||
                                    categoryImages.profileUrl
                                  }
                                  className={
                                    community?.communityBlocked ||
                                    community?.block
                                      ? "blockedMessage communityProfileImage"
                                      : "communityProfileImage"
                                  }
                                  alt=""
                                />
                                <div className="mobWraplist">
                                  <div className="communitythumbnailTitle">
                                    {community?.name}
                                  </div>
                                  <div className="communitythumbnailDescription">
                                    {community?.communityBlocked ? (
                                      <p className="blockedTextMessage">
                                        This community has been blocked
                                      </p>
                                    ) : community?.block ? (
                                      <p className="blockedTextMessage">
                                        You have been blocked from this
                                        community
                                      </p>
                                    ) : (
                                      <p>
                                        {" "}
                                        {community?.noOfParticipants}{" "}
                                        {community?.noOfParticipants > 1
                                          ? "members"
                                          : "member"}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div
                                  className="joingroup"
                                  style={{ justifyContent: "flex-end" }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            )}
          </div>
        ) : (
          <div className="flex flex-col items-center py-20">
            <img
              src={image}
              style={{ width: "100px", height: "100px" }}
              alt=""
            />
            <div className="text-gray-500 text-2xl pt-5"> No communities</div>
          </div>
        )}
      </div>
    </PullToRefreshComponent>
  );
}

export default YourCommunity;

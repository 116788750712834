import React, { useState, useEffect } from "react";
import { Tabs, Button } from "antd";
import type { TabsProps } from "antd";
import YourCommunity from "../YourCommunity";
import ForYou from "../ForYou";
import Discover from "../Discover";
import banner from "../../../assets/images/community-banner.png";
import { request } from "../../../Utils/request";
import "./CommunityTab.css";
import CreateCommunityPopup from "../CreateCommunityPopup";
import SearchResults from "../../Header/SearchResults";
import { Input, Space } from "antd";
import { isMobile } from "../../../Utils/mobileSettings";
import AddIcon from "../../../assets/newUiImages/addIcon.svg";
import { checkEmailVerified } from "../../../Utils/checkEmailVerified";
import EmailVerificationAlert from "../../EmailVerificationAlert/EmailVerificationAlert";

type Props = {};

const { Search } = Input;

function CommunityTab({}: Props) {
  // const { Search } = Input;
  const [categories, setCategories] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const initialActiveTabKey = localStorage.getItem("activeTabKey") || "1";
  const [activeTabKey, setActiveTabKey] = useState<string>(initialActiveTabKey);
  const [highlightedCardIndex, setHighlightedCardIndex] = useState<number>(-1);
  const [categoryId, setCategoryId] = useState<string>("");
  const [selectedCategoryId, setSelectedCategoryId] = useState<string>("");
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [communityList, setCommunityList] = useState<any>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]); // State to store search results
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [searchText, setSearchText] = useState<string>("");
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [toggleSelected, setToggleSelected] = useState<boolean>(false);
  const [triggerSearch, setTriggerSearch] = useState<number>(0);
  const [isEmailVerified, setIsEmailVerified] = useState<any>()
  const loginVia = localStorage.getItem("loginInVia")

  const getEmail = async()=>{
    const isVerified = await checkEmailVerified()
    setIsEmailVerified(isVerified)
  }
  useEffect(() => {
    getEmail()
  },[])

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `Your Community`,
      children: (
        <YourCommunity
          yourCommunityList={communityList}
          loader={loader}
          refresh={toggleRefresh}
          selectedCategoryId={selectedCategoryId}
          queryText={searchText}
          triggerSearch={triggerSearch}
        />
      ),
    },
    {
      key: "2",
      label: `For You`,
      children: (
        <ForYou
          selectedCategoryId={selectedCategoryId}
          queryText={searchText}
          triggerSearch={triggerSearch}
        />
      ),
    },
    {
      key: "3",
      label: `Discover`,
      children: (
        <Discover
          categoryId={categoryId}
          toggleSelected={toggleSelected}
          selectedCategoryId={selectedCategoryId}
          queryText={searchText}
          triggerSearch={triggerSearch}
        />
      ),
    },
  ];

  useEffect(() => {
    const getCommunityPosts = async () => {
      const resp = await request(`/member/communities/categories`);

      if (resp) {
        const datafetch = resp;
        setLoader(false);

        setCategories(datafetch);
      } else {
        setLoader(false);
      }
    };
    if (activeTabKey?.length === 0) {
      // setActiveTabKey("1")
    }

    getCommunityPosts();
    // getcommunityData();
  }, [toggleRefresh]);

  const handleonClick = async (id: any, index: number) => {
    if (categoryId === id) {
      setCategoryId("");
      setHighlightedCardIndex(-1);
      setToggleSelected(!toggleSelected);
    } else {
      setCategoryId(id);
      setToggleSelected(!toggleSelected);
      const resp = await request(
        `/member/current/communities?categoryId=${id}`
      );

      if (resp) {
        setLoader(false);
        setActiveTabKey("3");
        setHighlightedCardIndex(index);
        // setCategories(datafetch);
        const discoverTabContainer = document.getElementById(
          "discoverTabContainer"
        );
        if (discoverTabContainer) {
          discoverTabContainer.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setLoader(false);
      }
    }
  };
  useEffect(() => {
    if (activeTabKey !== "3") {
      setHighlightedCardIndex(-1);
      setCategoryId("");
    }
    clearSearchText();
  }, [activeTabKey]);

  useEffect(() => {
    if (createPopup) {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar = document.getElementById(
          "createCommunityPopupDiv"
        );
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "fixed";
        }
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar = document.getElementById(
          "createCommunityPopupDiv"
        );
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "unset";
        }
      }
    }
  }, [createPopup]);

  const clearSearchText = () => {
    setSearchText("");
    setSelectedCategoryId("");
  };
  const handleCommunitySearch = () => {
    const inputElement = document.getElementById("communitySearch");
    if (inputElement) {
      inputElement.blur();
    }
  };
  const handleSearch = async (value: string) => {
    console.log("value", value);
    setSelectedCategoryId("");
    try {
      const resp = await request(
        `/member/current/community-category/search?searchTerm=${value}&isMyCommunity=${
          activeTabKey === "1"
        }`
      );
      if (resp) {
        const datafetch = resp;
        setShowSearchResults(true);
        if (datafetch?.length > 1) {
          setSearchResults([
            {
              categoryId: null,
              categoryName: "All communities",
            },
            ...datafetch,
          ]);
        } else {
          setSearchResults(datafetch);
        }
      } else {
        setShowSearchResults(false);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      clearSearchText();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [searchText]);

  const handleTabChange = (key: string) => {
    setActiveTabKey(key);
    localStorage.setItem("activeTabKey", key);
  };

  return (
    <div className="w-full h-full relative">
      <img
        src={banner}
        className="rounded-tr-2xl rounded-tl-2xl communityBanner"
        alt="community banner"
      />

      <div className="communityCategories">
        <>
          <Search
            placeholder="Search Community..."
            allowClear
            style={{ marginBottom: "-50px", borderRadius: "20px" }}
            id="communitySearch"
            enterButton
            size="large"
            value={searchText}
            onChange={(e: any) => {
              setSearchText(e.target.value);
              setTriggerSearch((prevState) => prevState + 1);
              handleSearch(e.target.value);
            }}
            onSearch={handleCommunitySearch}
          />
          {searchText?.length > 0 &&
            searchText !== " " &&
            showSearchResults && (
              <SearchResults
                results={searchResults}
                queryText={searchText}
                setShowSearchResults={setShowSearchResults}
                clearSearchText={clearSearchText}
                setSelectedCategoryId={setSelectedCategoryId}
                handleTriggerSearch={() =>
                  setTriggerSearch((prevState) => prevState + 1)
                }
              />
            )}
          {categories?.length > 0 &&
            categories?.map((category, index) => (
              <div
                className={`communityCategoryCard ${
                  highlightedCardIndex === index ? "highlightedCard" : ""
                }`}
                key={index}
                onClick={() => handleonClick(category?.id, index)}
              >
                <div className="cardContent">
                  <span className="text-7xl mr-2 text-[#298717] font-bold communityNumber">
                    {category?.noOfCommunities}
                  </span>
                  <span className="communityCategoryText">
                    {category?.name}
                  </span>
                </div>
              </div>
            ))}
        </>
      </div>
      <div className="relative">
        <div className="absolute top-0 right-0 mt-5 mr-10 z-10 yourComCreate">
          <Button
            className="createButton showDesktop"
            type="primary"
            disabled={(loginVia === "email" && !isEmailVerified)}
            onClick={() => {
              setCreatePopup(true);
            }}
            style={{ marginTop: "-1px" }}
          >
            Create
          </Button>
          <div
            className="plusCreate showMobileIcon"
            // type="primary"
            onClick={() => {
              if(!(loginVia === "email" && !isEmailVerified)){
              setCreatePopup(true);
            }}}
            style={{ marginTop: "-1px", backgroundColor:"white",borderRadius: "50%" }}
          >
            <div className="" style={{ fontSize: "45px" , opacity:loginVia === "email" && !isEmailVerified ? "0.35" : "1" }}>
              {/* + */}
              <img
                src={AddIcon}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0.5px solid gray",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
        </div>

        <div
          id="discoverTabContainer"
          className="tabPanelBox"
          style={{
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {isMobile && (
              <>
              {(loginVia === "email" && !isEmailVerified) && <div className="mt-2"><EmailVerificationAlert from="tabs"/></div>}
              <div style={{ marginTop: "10px" }} className="communitySearchBar">
                <Search
                  placeholder="Search Community..."
                  allowClear
                  style={{ marginBottom: "-50px", borderRadius: "20px" }}
                  enterButton
                  size="large"
                  id="communitySearch"
                  value={searchText}
                  onChange={(e: any) => {
                    setSearchText(e.target.value);
                    if (e?.target?.value?.length === 0) {
                      document.getElementById("communitySearch")?.blur();
                    }
                    handleSearch(e.target.value);
                  }}
                  onSearch={handleCommunitySearch}
                />
                {searchText?.length > 0 &&
                  searchText !== " " &&
                  showSearchResults && (
                    <SearchResults
                      results={searchResults}
                      setSelectedCategoryId={setSelectedCategoryId}
                      queryText={searchText}
                      setShowSearchResults={setShowSearchResults}
                      clearSearchText={clearSearchText}
                      handleTriggerSearch={() =>
                        setTriggerSearch((prevState) => prevState + 1)
                      }
                    />
                  )}
              </div>
              </>
            )}
            <Tabs
              className="w-full h-full tabsBody"
              activeKey={activeTabKey}
              defaultActiveKey="1"
              items={items}
              onChange={handleTabChange}
            />
          </div>
          {createPopup && (
            <div
              className={createPopup ? "popup Show" : "popup"}
              id="createCommunityPopupDiv"
            >
              <CreateCommunityPopup
                onCancelClick={() => {
                  setCreatePopup(false);
                }}
                onSaveClick={() => {
                  setToggleRefresh(!toggleRefresh);
                  setCreatePopup(false);
                }}
                isOpen={createPopup}
                communityCategory={categories}
              />
            </div>
          )}
        </div>
        <div className={createPopup ? "overlay show" : "overlay"}></div>
      </div>
    </div>
  );
}

export default CommunityTab;

import { Tabs, Form, Radio, Select, Input, Button, ConfigProvider } from "antd";
import React, { useState, useEffect } from "react";
import matrimonyCoverImg from "../../assets/images/matrimonyCover.png";
import YourProfiles from "./YourProfiles";
import Explore from "./Explore";
import MultiStepForm from "../../Components/MatrimonyForms/MultiStepForm";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import filter from "../../assets/newUiImages/filter.png";
import filterApplied from "../../assets/newUiImages/filter_applied.svg";
import { isMobile, isMobileOnly } from "../../Utils/mobileSettings";
import { DownOutlined } from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import AddIcon from "../../assets/newUiImages/addIcon.svg";
import { checkEmailVerified } from "../../Utils/checkEmailVerified";
import EmailVerificationAlert from "../EmailVerificationAlert/EmailVerificationAlert";

const { TabPane } = Tabs;
const { Option } = Select;

type Props = {};

const MatrimonyTab: React.FC<Props> = () => {
  const activeTabKey = localStorage.getItem("MatrimonyActiveTab") || "1";
  const [activeTab, setActiveTab] = useState(activeTabKey);
  const [matrimonyList, setMatrimonyList] = useState<any[]>([]);
  const [showmultistepform, setshowmultistepform] = useState<boolean>(false);
  const [toggleRefresh, setToggleRefresh] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);
  const [isFilter, setIsFilter] = useState<boolean>(false);
  const [draftId, setDraftId] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const [religionData, setReligionData] = useState<any>();
  const [educationData, setEducationData] = useState<any>();
  const [matrimonyType, setMatrimonyType] = useState<string>("");
  const [showFilterPanel, setShowFilterPanel] = useState(!isMobileOnly);
  const [filterOptions, setFilterOptions] = useState({
    minAge: 18,
    maxAge: 70,
    minHeight: 120,
    maxHeight: 213,
    food: "",
    gender: "",
    religion: [] as string[],
    occupation: [] as string[],
  });
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchCLicked, setSearchClicked] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [isEmailVerified, setIsEmailVerified] = useState<any>();
  const loginVia = localStorage.getItem("loginInVia");

  const getEmail = async () => {
    const isVerified = await checkEmailVerified();
    setIsEmailVerified(isVerified);
  };
  useEffect(() => {
    getEmail();
  }, []);

  let apiCount = 0;

  const ageOptions = [];
  for (let i = 18; i <= 70; i++) {
    ageOptions.push(i);
  }

  // Height options
  const heightOptions = [];
  for (let i = 135; i <= 213; i++) {
    heightOptions.push(i);
  }

  /**
   * Handles the submission of the filter form.
   * @param page The page number.
   */
  const handleFilterFormSubmit = async (page: number) => {
    // Create a new object with non-null properties
    const matrimonyType1 = matrimonyType.toString();
    const matrimonyType1Lenght = matrimonyType1?.length;
    let newFilterOptions = {};
    if (matrimonyType1Lenght !== 0) {
      newFilterOptions = {
        ...(filterOptions?.minAge !== 18 && { minAge: filterOptions.minAge }),
        ...(filterOptions?.maxAge !== 70 && { maxAge: filterOptions.maxAge }),
        ...(filterOptions?.minHeight !== 120 && {
          minHeight: filterOptions.minHeight,
        }),
        ...(filterOptions?.maxHeight !== 213 && {
          maxHeight: filterOptions.maxHeight,
        }),
        ...(filterOptions?.food !== "" && { food: filterOptions.food }),
        ...(filterOptions?.gender !== "" && {
          gender: filterOptions.gender,
        }),
        ...(filterOptions?.religion?.length > 0 && {
          religion: filterOptions.religion,
        }),
        ...(filterOptions?.occupation?.length > 0 && {
          occupation: filterOptions.occupation,
        }),
        associateWith: matrimonyType1.slice(15, matrimonyType1Lenght),
      };
    } else {
      newFilterOptions = {
        ...(filterOptions?.minAge !== 18 && { minAge: filterOptions.minAge }),
        ...(filterOptions?.maxAge !== 70 && { maxAge: filterOptions.maxAge }),
        ...(filterOptions?.minHeight !== 120 && {
          minHeight: filterOptions?.minHeight,
        }),
        ...(filterOptions?.maxHeight !== 213 && {
          maxHeight: filterOptions?.maxHeight,
        }),
        ...(filterOptions?.food !== "" && { food: filterOptions.food }),
        ...(filterOptions?.gender !== "" && {
          gender: filterOptions?.gender,
        }),
        ...(filterOptions?.religion?.length > 0 && {
          religion: filterOptions?.religion,
        }),
        ...(filterOptions?.occupation?.length > 0 && {
          occupation: filterOptions?.occupation,
        }),
      };
    }
    setShowFilterPanel(false);

    try {
      const payload = {
        method: "POST",
        body: JSON.stringify({
          ...newFilterOptions,
        }),
      };

      const resp = await request(
        `/member/current/matrimony-profile/filter?page=${page}&size=10`,
        payload
      );
      setIsFilter(true);

      if (resp) {
        if (page === 0) {
          setMatrimonyList(resp?.content);
          setHasMore(true);
        } else if (resp?.content?.length > 0 && page && page > 0) {
          setMatrimonyList((prevData) => {
            if (Array.isArray(resp?.content) && resp.content.length > 0) {
              return [...prevData, ...resp.content];
            }
            return prevData;
          });
        } else if (resp?.content?.length === 0) {
          setHasMore(false);
        }
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleTabChange = (activeKey: string) => {
    setActiveTab(activeKey);
    localStorage.setItem("MatrimonyActiveTab", activeKey);
  };

  const handleFormChange = (key: string, value: any) => {
    setFilterOptions({
      ...filterOptions,
      [key]: value,
    });
  };

  const handleSearchbyKeyword = async (page: any) => {
    const inputElement = document.getElementById("searchBykeywordWeb");
    const inputElementMobile = document.getElementById("searchBykeywordMobile");
    if (inputElementMobile) {
      inputElementMobile.blur();
    }
    if (inputElement) {
      inputElement.blur();
    }

    const resp = await request(
      `/member/current/matrimony-profile/search?searchItem=${searchText}${matrimonyType}&page=${page}&size=10`
    );
    if (resp) {
      console.log(resp);
      if (page === 0) {
        console.log(resp?.content);
        setMatrimonyList(resp?.content);
      } else {
        let oldList = [...matrimonyList];
        oldList = [...oldList, ...resp?.content];
        setMatrimonyList(oldList);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getFilterOptionsData = async () => {
    const resp = await request(`/member/current/matrimony-profile/religion`);
    if (resp?.length) {
      const newmatrimonyList = resp;
      setReligionData(newmatrimonyList);
    } else {
    }
    const resp1 = await request(
      `/member/current/matrimony-profile/qualifications`
    );
    if (resp1?.length) {
      const newList = resp1;
      setEducationData(newList);
    } else {
    }
  };

  const getMatrimonyExploreData = async (page?: number) => {
    let requestPromise = request(
      `/member/current/matrimony-profile/all?page=${page}&size=10${matrimonyType}`
    );
    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );
    apiCount++;

    try {
      const resp = await Promise.race([requestPromise, timeoutPromise]);

      if (!resp) {
        const cachedResponse = await getCachedResponse(
          `/member/current/matrimony-profile/all?page=${page}&size=10${matrimonyType}`
        );
        if (cachedResponse) {
          setMatrimonyList(cachedResponse);
          setLoader(false);
        } else {
          console.error("Failed to get cached data");
        }
        return;
      }

      if (resp.content && resp.content.length >= 0) {
        const newMatrimonyList = resp;

        if (newMatrimonyList.content.length === 0) {
          setHasMore(false);
        }

        if (page === 0) {
          setMatrimonyList(resp.content);
          cacheResponse(
            `/member/current/matrimony-profile/all?page=${page}&size=10${matrimonyType}`,
            resp.content
          );
        } else if (newMatrimonyList.content.length) {
          setMatrimonyList((prevData) => {
            if (Array.isArray(resp?.content) && resp.content.length > 0) {
              return [...prevData, ...resp.content];
            }
            return prevData;
          });
          cacheResponse(
            `/member/current/matrimony-profile/all?page=${page}&size=10${matrimonyType}`,
            [...matrimonyList, ...resp.content]
          );
        }
        setLoader(false);
      } else {
        // Use cached data if available
        const cachedResponse = await getCachedResponse(
          `/member/current/matrimony-profile/all?page=${page}&size=10${matrimonyType}`
        );

        if (cachedResponse) {
          setLoader(false);
          setMatrimonyList(cachedResponse);
          if (apiCount < 3) getMatrimonyExploreData(page);
        } else {
          console.error("Failed to get cached data");
        }
      }
    } catch (error) {
      // Handle timeout or other errors
      console.error("Error in getMatrimonyExploreData:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getFilterOptionsData();
  }, []);

  useEffect(() => {
    if (searchCLicked) {
      setCurrentPage(0);
      if (searchText?.length === 0) {
        setSearchClicked(false);
      } else {
        handleSearchbyKeyword(currentPage);
      }
    }
  }, [currentPage, searchCLicked]);

  useEffect(() => {
    isFilter && handleFilterFormSubmit(currentPage);
  }, [currentPage]);

  useEffect(() => {
    !isFilter && !searchCLicked && getMatrimonyExploreData(currentPage);
    setDraftId("");
  }, [currentPage, showmultistepform, toggleRefresh, matrimonyType]);

  const handleDraftClick = (id: string) => {
    localStorage.setItem("draftId", id);
    setshowmultistepform(true);
    setDraftId(id);
  };

  const handleMatrimonyType = (value: string) => {
    setMatrimonyType(value);
    setCurrentPage(0);
  };

  /**
   * Handles the action of clearing the filter options and resetting the page state.
   * @returns None
   */
  const handleFilterClear = () => {
    setIsFilter(false);
    form.resetFields();
    setFilterOptions({
      minAge: 18,
      maxAge: 70,
      minHeight: 120,
      maxHeight: 213,
      food: "",
      gender: "",
      religion: [],
      occupation: [],
    });
    setCurrentPage(0);
    getMatrimonyExploreData(0);
    setShowFilterPanel(false);
  };

  const handleRefresh = () => {
    setCurrentPage(0);
    setToggleRefresh((prev) => prev++);
  };

  useEffect(() => {
    setToggleRefresh((prev) => prev++);
  }, [showmultistepform]);

  useEffect(() => {
    if (showFilterPanel) {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar = document.getElementById(
          "matrimonyExploreList"
        );
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "fixed";
        }
      }
    } else {
      if (typeof window != "undefined" && window.document) {
        const containerWithOutSideBar = document.getElementById(
          "matrimonyExploreList"
        );
        if (containerWithOutSideBar) {
          containerWithOutSideBar.style.position = "unset";
        }
      }
    }
  }, [showFilterPanel]);

  return (
    <div className="">
      {showmultistepform ? (
        <div className="">
          <MultiStepForm
            onClose={() => setshowmultistepform(false)}
            draftId={draftId}
          />
        </div>
      ) : (
        <div>
          <div className="relative">
            {!isMobileOnly && (
              <img
                src={matrimonyCoverImg}
                alt="Matrimony Cover"
                className="rounded-t-xl w-full -mr-10 MatrimonyCover"
              />
            )}

            <>
              {activeTab === "2" && (
                <>
                  {
                    <div
                      className={showFilterPanel ? "filters show " : "filters"}
                    >
                      <div
                        className="bg-[#E3D0BB] opacity-90 p-3 m-4 rounded-xl mt-1 shadow-lg absolute -top-20  z-50 right-7  max-h-520 overflow-y-auto filtercontents md:top-5 "
                        style={{
                          maxHeight: "370px",
                        }}
                      >
                        {isMobile && (
                          <div
                            onClick={() => setShowFilterPanel(false)}
                            className="float-right text-[#804d31] font-semibold leading-8"
                          >
                            X
                          </div>
                        )}

                        <div className="flex items-center justify-center">
                          <h1 className="text-lg">Quick Search</h1>
                        </div>
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#7d8742",
                              borderRadius: 10,
                              colorPrimaryBorder: "#7d8742",
                            },
                          }}
                        >
                          <Form
                            form={form}
                            onFinish={() => {
                              setIsFilter(true);
                              handleFilterFormSubmit(0);
                              setCurrentPage(0);
                            }}
                          >
                            <Form.Item label="Age Range" className="mb-4">
                              <div className="flex ageRange">
                                <Form.Item
                                  name="minAge"
                                  className="mr-2"
                                  initialValue={18}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const maxAge = getFieldValue("maxAge");
                                        if (
                                          value &&
                                          maxAge &&
                                          value >= maxAge
                                        ) {
                                          return Promise.reject(() => {
                                            new Error("");
                                            toast.error("Invalid age range");
                                          });
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Select
                                    suffixIcon={
                                      <DownOutlined className="text-base text-[#686f2e]" />
                                    }
                                    style={{ width: "80px" }}
                                    placeholder="Min"
                                    onChange={(value: any) =>
                                      handleFormChange("minAge", value)
                                    }
                                  >
                                    {ageOptions?.map((option) => (
                                      <Option key={option} value={option}>
                                        {option}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                                <span className="mr-5 ageRangeTo"> To </span>
                                <Form.Item
                                  name="maxAge"
                                  className="ml-2"
                                  initialValue={70}
                                >
                                  <Select
                                    suffixIcon={
                                      <DownOutlined className="text-base text-[#686f2e]" />
                                    }
                                    style={{ width: "80px" }}
                                    placeholder="Max"
                                    onChange={(value) =>
                                      handleFormChange("maxAge", value)
                                    }
                                  >
                                    {ageOptions?.map((option) => (
                                      <Option key={option} value={option}>
                                        {option}
                                      </Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </Form.Item>

                            <Form.Item label="Height Range" className="mb-4">
                              <div className="flex heightRange">
                                <Form.Item
                                  name="minHeight"
                                  className="mr-0"
                                  initialValue={135}
                                  rules={[
                                    ({ getFieldValue }) => ({
                                      validator(_, value) {
                                        const maxHeight =
                                          getFieldValue("maxHeight");
                                        if (
                                          value &&
                                          maxHeight &&
                                          value >= maxHeight
                                        ) {
                                          return Promise.reject(() => {
                                            new Error("");
                                            toast.error("Invalid height range");
                                          });
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Select
                                    suffixIcon={
                                      <DownOutlined className="text-base text-[#686f2e]" />
                                    }
                                    style={{ width: "120px" }}
                                    placeholder="Min"
                                    onChange={(value) =>
                                      handleFormChange("minHeight", value)
                                    }
                                  >
                                    <Option value={135}>4'5" or 135cm</Option>
                                    <Option value={137}>4'6" or 137cm</Option>
                                    <Option value={140}>4'7" or 140cm</Option>
                                    <Option value={142}>4'8" or 142cm</Option>
                                    <Option value={145}>4'9" or 145cm</Option>
                                    <Option value={147}>4'10" or 147cm</Option>
                                    <Option value={149}>4'11" or 149cm</Option>
                                    <Option value={152}>5' or 152cm</Option>
                                    <Option value={155}>5'1" or 155cm</Option>
                                    <Option value={157}>5'2" or 157cm</Option>
                                    <Option value={160}>5'3" or 160cm</Option>
                                    <Option value={163}>5'4" or 163cm</Option>
                                    <Option value={165}>5'5" or 165cm</Option>
                                    <Option value={167}>5'6" or 167cm</Option>
                                    <Option value={170}>5'7" or 170cm</Option>
                                    <Option value={172}>5'8" or 172cm</Option>
                                    <Option value={175}>5'9" or 175cm</Option>
                                    <Option value={177}>5'10" or 177cm</Option>
                                    <Option value={180}>5'11" or 180cm</Option>
                                    <Option value={182}>6' or 182cm</Option>
                                    <Option value={185}>6'1" or 185cm</Option>
                                    <Option value={187}>6'2" or 187cm</Option>
                                    <Option value={190}>6'3" or 190cm</Option>
                                    <Option value={193}>6'4" or 193cm</Option>
                                    <Option value={195}>6'5" or 195cm</Option>
                                    <Option value={197}>6'6" or 197cm</Option>
                                    <Option value={200}>6'7" or 200cm</Option>
                                    <Option value={202}>6'8" or 202cm</Option>
                                    <Option value={205}>6'9" or 205cm</Option>
                                    <Option value={208}>6'10" or 208cm</Option>
                                    <Option value={210}>6'11" or 210cm</Option>
                                    <Option value={213}>
                                      7' or 213cm and above
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <span className="mr-2 heightRangeTo">To</span>
                                <Form.Item
                                  name="maxHeight"
                                  className="ml-0"
                                  initialValue={213}
                                >
                                  <Select
                                    suffixIcon={
                                      <DownOutlined className="text-base text-[#686f2e]" />
                                    }
                                    style={{ width: "120px" }}
                                    placeholder="Max"
                                    onChange={(value) =>
                                      handleFormChange("maxHeight", value)
                                    }
                                  >
                                    <Option value={135}>4'5" or 135cm</Option>
                                    <Option value={137}>4'6" or 137cm</Option>
                                    <Option value={140}>4'7" or 140cm</Option>
                                    <Option value={142}>4'8" or 142cm</Option>
                                    <Option value={145}>4'9" or 145cm</Option>
                                    <Option value={147}>4'10" or 147cm</Option>
                                    <Option value={149}>4'11" or 149cm</Option>
                                    <Option value={152}>5' or 152cm</Option>
                                    <Option value={155}>5'1" or 155cm</Option>
                                    <Option value={157}>5'2" or 157cm</Option>
                                    <Option value={160}>5'3" or 160cm</Option>
                                    <Option value={163}>5'4" or 163cm</Option>
                                    <Option value={165}>5'5" or 165cm</Option>
                                    <Option value={167}>5'6" or 167cm</Option>
                                    <Option value={170}>5'7" or 170cm</Option>
                                    <Option value={172}>5'8" or 172cm</Option>
                                    <Option value={175}>5'9" or 175cm</Option>
                                    <Option value={177}>5'10" or 177cm</Option>
                                    <Option value={180}>5'11" or 180cm</Option>
                                    <Option value={182}>6' or 182cm</Option>
                                    <Option value={185}>6'1" or 185cm</Option>
                                    <Option value={187}>6'2" or 187cm</Option>
                                    <Option value={190}>6'3" or 190cm</Option>
                                    <Option value={193}>6'4" or 193cm</Option>
                                    <Option value={195}>6'5" or 195cm</Option>
                                    <Option value={197}>6'6" or 197cm</Option>
                                    <Option value={200}>6'7" or 200cm</Option>
                                    <Option value={202}>6'8" or 202cm</Option>
                                    <Option value={205}>6'9" or 205cm</Option>
                                    <Option value={208}>6'10" or 208cm</Option>
                                    <Option value={210}>6'11" or 210cm</Option>
                                    <Option value={213}>
                                      7' or 213cm and above
                                    </Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            </Form.Item>
                            <Form.Item label="Food Preference" className="mb-4">
                              <Radio.Group
                                value={filterOptions.food}
                                buttonStyle="solid"
                                onChange={(e) =>
                                  handleFormChange("food", e.target.value)
                                }
                              >
                                <Radio.Button value="VEGETARIAN">
                                  Veg
                                </Radio.Button>
                                <Radio.Button value="NON_VEGETARIAN">
                                  Non-Veg
                                </Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Preference" className="mb-4">
                              <Radio.Group
                                value={filterOptions.gender}
                                buttonStyle="solid"
                                onChange={(e) =>
                                  handleFormChange("gender", e.target.value)
                                }
                              >
                                <Radio.Button value="FEMALE">
                                  Bride
                                </Radio.Button>
                                <Radio.Button value="MALE">Groom</Radio.Button>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item label="Religion" className="mb-4">
                              <Select
                                suffixIcon={
                                  <DownOutlined className="text-base text-[#686f2e]" />
                                }
                                mode="multiple"
                                maxTagCount={"responsive"}
                                placeholder="Select Religion"
                                value={filterOptions.religion}
                                onChange={(values) =>
                                  handleFormChange("religion", values)
                                }
                              >
                                {religionData?.map((religion: any) => (
                                  <Option
                                    key={religion.name}
                                    value={religion.name}
                                  >
                                    {religion.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label="Highest Qualification"
                              className="mb-4"
                            >
                              <Select
                                suffixIcon={
                                  <DownOutlined className="text-base text-[#686f2e]" />
                                }
                                mode="multiple"
                                maxTagCount={"responsive"}
                                placeholder="Select Highest Qualification"
                                value={filterOptions.occupation}
                                onChange={(values) =>
                                  handleFormChange("occupation", values)
                                }
                              >
                                {educationData?.map((occupations: any) => (
                                  <Option
                                    key={occupations.id}
                                    value={occupations.name}
                                  >
                                    {occupations.name}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item className="text-right">
                              <Button
                                type="primary"
                                htmlType="submit"
                                className="bg-[#7d8742] hover:bg-green-600"
                              >
                                Apply
                              </Button>
                              <Button
                                type="primary"
                                className="bg-[#7d8742] hover:bg-green-600 ml-2"
                                onClick={handleFilterClear}
                              >
                                Clear
                              </Button>
                            </Form.Item>
                          </Form>
                        </ConfigProvider>
                      </div>

                      <div
                        className="bg-[#E3D0BB] opacity-90 p-3 m-4 rounded-xl shadow-lg absolute bottom-2 right-7 h-5/7 flex filtercontents"
                        style={{ maxHeight: "520px" }}
                      >
                        <ConfigProvider
                          theme={{
                            token: {
                              colorPrimary: "#7d8742",
                              borderRadius: 10,
                              colorPrimaryBorder: "#7d8742",
                            },
                          }}
                        >
                          <Input.Search
                            id="searchBykeywordWeb"
                            placeholder="Search by keyword"
                            // onSearch={handleSearchbyKeyword}
                            onSearch={() => {
                              handleSearchbyKeyword(0);
                              setSearchClicked(true);
                            }}
                            enterButton={
                              <Button
                                type="primary"
                                className="bg-[#7d8742] hover:bg-green-600"
                                style={{ maxHeight: "40px" }}
                              >
                                Search
                              </Button>
                            }
                            allowClear
                            onChange={(e) => {
                              setSearchText(e.target.value);
                              if (!e.target.value) {
                                setCurrentPage(0);
                                getMatrimonyExploreData(0);
                              }
                            }}
                          />
                        </ConfigProvider>
                      </div>
                    </div>
                  }
                </>
              )}
            </>

            <div
              className={showFilterPanel ? "overlay show" : "overlay"}
              onClick={() => setShowFilterPanel(false)}
            ></div>
          </div>
          <div className="mt-2 relative yourComCreate">
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7d8742",
                  borderRadius: 10,
                  colorPrimaryBorder: "#7d8742",
                },
              }}
            >
              <Button
                className="absolute top-0 right-2 font-medium bg-[#7d8742] text-white text-lg text-center w-24 h-11 cursor-pointer z-50 hover:bg-green-600 showDesktop"
                type="primary"
                onClick={() => {
                  setshowmultistepform(true);
                }}
                disabled={loginVia === "email" && !isEmailVerified}
              >
                Create
              </Button>

              {isMobile && (
                <div
                  className="plusCreate showMobileIcon"
                  // type="primary"
                  onClick={() => {
                    if (!(loginVia === "email" && !isEmailVerified)) {
                      setshowmultistepform(true);
                    }
                  }}
                  style={{
                    marginTop: "-1px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <div
                    className=""
                    style={{
                      fontSize: "45px",
                      opacity:
                        loginVia === "email" && !isEmailVerified ? "0.35" : "1",
                    }}
                  >
                    {/* + */}
                    <img
                      src={AddIcon}
                      alt=""
                      style={{
                        width: "100%",
                        height: "100%",
                        border: "0.5px solid gray",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
              )}
            </ConfigProvider>
          </div>
          <div className="mt-3 relative mb-2">
            {activeTab === "2" && (
              <>
                <Select
                  defaultValue="Both"
                  className="absolute top-12 right-3 font-medium  text-lg text-center h-10 cursor-pointer z-50 showDesktop typeDropdown matrimony-typeDropdown searchbardiscover mb-2"
                  bordered={false}
                  suffixIcon={
                    <DownOutlined className="text-base text-[#686f2e]" />
                  }
                  placeholder="Select community type"
                  optionFilterProp="children"
                  onChange={handleMatrimonyType}
                  options={[
                    {
                      value: "&associateWith=OPEN_TO_ALL",
                      label: "Public",
                    },
                    {
                      value: "&associateWith=ONLY_COMMUNITY",
                      label: "Community linked",
                    },
                    {
                      value: "",
                      label: "Both",
                    },
                  ]}
                />
              </>
            )}
          </div>
          <div className="mt-3 pr-3 relative">
            {isMobileOnly && activeTab === "2" && (
              <>
                <div className="fixed right-2 -mt-14 z-50">
                  <div
                    className="cursor-pointer sticky"
                    onClick={() => setShowFilterPanel(true)}
                  >
                    {!isFilter ? (
                      <img src={filter} alt="" style={{ height: "30px" }} />
                    ) : (
                      <img
                        src={filterApplied}
                        alt=""
                        style={{ height: "30px" }}
                      />
                    )}
                  </div>
                </div>
                <div className="ml-3 -mr-1">
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#7d8742",
                        borderRadius: 10,
                        colorPrimaryBorder: "#7d8742",
                      },
                    }}
                  >
                    {loginVia === "email" && !isEmailVerified && (
                      <div className="my-2">
                        <EmailVerificationAlert from="tabs" />
                      </div>
                    )}
                    <Input.Search
                      id="searchBykeywordMobile"
                      placeholder="Search by keyword"
                      onSearch={() => {
                        handleSearchbyKeyword(0);
                        setSearchClicked(true);
                      }}
                      enterButton={
                        <Button
                          type="primary"
                          className="bg-[#7d8742] hover:bg-green-600"
                          style={{ maxHeight: "40px" }}
                        >
                          Search
                        </Button>
                      }
                      allowClear
                      onChange={(e) => {
                        setSearchText(e.target.value);
                        if (!e.target.value) {
                          setCurrentPage(0);
                          getMatrimonyExploreData(0);
                        }
                      }}
                    />
                  </ConfigProvider>
                  <>
                    <Select
                      style={{ width: "200px", borderRadius: "50px" }}
                      defaultValue="Both"
                      className="float-right font-medium  text-lg text-center h-10 cursor-pointer z-1  typeDropdown searchbardiscover mb-2"
                      bordered={false}
                      suffixIcon={
                        <DownOutlined className="text-base text-[#686f2e]" />
                      }
                      placeholder="Select community type"
                      optionFilterProp="children"
                      onChange={handleMatrimonyType}
                      options={[
                        {
                          value: "&associateWith=OPEN_TO_ALL",
                          label: "Public",
                        },
                        {
                          value: "&associateWith=ONLY_COMMUNITY",
                          label: "Community linked",
                        },
                        {
                          value: "",
                          label: "Both",
                        },
                      ]}
                    />
                  </>
                </div>
              </>
            )}

            <Tabs
              defaultActiveKey="1"
              activeKey={activeTab}
              onChange={handleTabChange}
              className="matrimonyTabswrapper relative z-0"
            >
              <TabPane tab="Your Profiles" key="1">
                {loginVia === "email" && !isEmailVerified && isMobile && (
                  <div className="mt-2">
                    <EmailVerificationAlert from="tabs" />
                  </div>
                )}
                <YourProfiles
                  onDraftClick={handleDraftClick}
                  refresh={toggleRefresh}
                />
              </TabPane>
              <TabPane tab="Explore" key="2">
                <InfiniteScroll
                  dataLength={matrimonyList?.length}
                  next={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  hasMore={hasMore}
                  loader={<></>}
                >
                  <Explore
                    matrimonyList={matrimonyList}
                    handleRefresh={handleRefresh}
                    loader={loader}
                  />
                </InfiniteScroll>
              </TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </div>
  );
};

export default MatrimonyTab;

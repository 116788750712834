import React, { useEffect, useState } from "react";
import { Button } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../Loader/Loader";
import {
  API_TIMEOUT_INTERVAL,
  cacheResponse,
  getCachedResponse,
  request,
} from "../../Utils/request";
import "./ForYou.css";
import { useNavigate } from "react-router-dom";
import { UserAddOutlined, IssuesCloseOutlined } from "@ant-design/icons";
import connection from "../../assets/images/connection_3.png";
import { toast } from "react-toastify";
import image from "../../assets/images/imageplaceholder.png";
import PullToRefreshComponent from "../../PullToRefreshComponent/PullToRefreshComponent";
import { checkEmailVerified } from "../../Utils/checkEmailVerified";

type Props = {
  selectedCategoryId: string;
  queryText: string;
  triggerSearch?: number;
};

function ForYou(props: Props) {
  const [forYouData, SetForYouData] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const [joinStatus, setJoinStatus] = useState("Join Group");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [toggleRefresh, setToggleRefresh] = useState<boolean>(false);
  const [isEmailVerified, setIsEmailVerified] = useState<any>()
  const loginVia = localStorage.getItem("loginInVia")

  const getEmail = async()=>{
    const isVerified = await checkEmailVerified()
    setIsEmailVerified(isVerified)
  }
  useEffect(() => {
    getEmail()
  },[])


  let apiCount = 0;

  const navigate = useNavigate();
  useEffect(() => {
    // window.scrollTo({ top: 900, behavior: "smooth" });
  }, []);

  const fetchImages = async (page?: number) => {
    const url =
      props?.queryText.trim()?.length > 0 &&
      props?.selectedCategoryId.trim()?.length > 0
        ? `/member/current/community/search?communityName=${
            props?.queryText
          }&communityCategoryId=${
            props?.selectedCategoryId !== "" ? props?.selectedCategoryId : null
          }&isMyCommunity=false`
        : `/member/current/community/recommendations?page=${page}&size=10`;
    let requestPromise = request(url);
    let timeoutPromise = new Promise((resolve) =>
      setTimeout(resolve, API_TIMEOUT_INTERVAL)
    );
    apiCount++;

    try {
      const res = await Promise.race([requestPromise, timeoutPromise]);

      if (!res) {
        const cachedResponse = await getCachedResponse(
          `/member/current/community/recommendations?page=${page}&size=10`
        );

        if (cachedResponse) {
          SetForYouData(cachedResponse);
          setLoader(false);
        } else {
          console.error("Failed to get cached data");
        }
        return;
      }

      if (res?.content?.length === 0) {
        setHasMore(false);
      }
      if (
        props?.queryText.trim()?.length > 0 &&
        props?.selectedCategoryId.trim()?.length > 0
      ) {
        SetForYouData(res);
        return;
      }

      if (res?.content?.length >= 0) {
        // let oldData = [...forYouData];
        // oldData = [...oldData, ...res?.content];
        // SetForYouData(oldData);
        // cacheResponse(
        //   `/member/current/community/recommendations?page=${page}&size=10`,
        //   oldData
        // );
        // setLoader(false);
        if (page === 0) {
          SetForYouData(res?.content);
          setHasMore(true);
           cacheResponse(
          `/member/current/community/recommendations?page=${page}&size=10`,
          res?.content
        );
        } else {
          SetForYouData((prevCommunityList: any) => [
            ...prevCommunityList,
            ...res?.content,
          ]);
          cacheResponse(
            `/member/current/memberships?page=${page}&size=10`,
            forYouData
          );
        }
        setLoader(false);
      } else {
        setLoader(false);
        // Use cached data if available
        const cachedResponse = await getCachedResponse(
          `/member/current/community/recommendations?page=${page}&size=10`
        );

        if (cachedResponse) {
          SetForYouData(cachedResponse);
          setLoader(false);
          if (apiCount < 3) fetchImages(page);
        } else {
          console.error("Failed to get cached data");
        }
      }
    } catch (error) {
      // Handle timeout or other errors
      console.error("Error in fetchImages:", error);
    }
  };
  useEffect(() => {
    if (props?.queryText.trim()?.length === 0) {
      fetchImages(0);
      setCurrentPage(0);
    }
  }, [props?.queryText]);

  useEffect(() => {
    fetchImages(0);
  }, [props?.triggerSearch]);

  useEffect(() => {
    fetchImages(currentPage);
  }, [currentPage, toggleRefresh]);

  const handleJoinCommunity = async (communityId: any) => {
    try {
      const body = {
        method: "POST",
      };
      const response = await request(
        `/member/current/community/join/${communityId}`,
        body
      );
      if (response.communityId) {
        let newForYouData: any[] = [...forYouData];
        let index = newForYouData.findIndex(
          (item: any) => item?.id === response.communityId
        );

        newForYouData[index] = {
          ...newForYouData[index],
          requested: true,
        };
        SetForYouData(newForYouData);
        toast.success("Your request has been sent for approval!");
        setJoinStatus("Requested");
      }else{
        toast.error("Membership request already sent, please wait for the admin to respond.");
        }
    } catch (error) {
      toast.error("Error sendig request. Please try again later.");
    }
  };
  const handleRefresh = async (): Promise<void> => {
    setToggleRefresh(!toggleRefresh);
  };

  return (
    <PullToRefreshComponent
      onRefresh={() => {
        handleRefresh();
      }}
    >
      <div style={{ minHeight: "250px" }}>
        {loader ? (
          <div className="loader mt-22">
            <Loader />{" "}
          </div>
        ) : (
          <div>
            <div className="infoBox">
              {" "}
              <p className="text-base">
                <p className="text-base">
                  "For you" is a feature designed to enhance a user's online
                  experience by recommending new online communities or forums
                  based on their existing interests and affiliations.
                </p>

                <p className="text-base">
                  These recommendations often rely on algorithms that take into
                  account factors such as the user's previous interactions,
                  engagement levels, and shared interests with others.
                  Ultimately, "Suggested Communities" aims to connect
                  individuals with like-minded peers and expand their online
                  network, fostering a more enriching and diverse online
                  experience.
                </p>
              </p>
            </div>
            <div className="topPanelHead">
              <h2 className="topPanelHeadColL ">
                Recommended communities for you to join
              </h2>
              <div className="topPanelHeadColR"></div>
            </div>
            <InfiniteScroll
              dataLength={forYouData?.length}
              next={() => {
                setCurrentPage(currentPage + 1);
              }}
              hasMore={hasMore}
              loader={<></>}
            >
              <div className="thumbnailBox">
                {forYouData?.length > 0 ? (
                  <div className="discoverthumbnailRowForThree">
                    {forYouData?.map((data: any, index) => (
                      <div
                        className="discoverthumbnailRowForThreeColumn"
                        key={data.id}
                        onClick={() =>
                          navigate(`/communityPreview/${data?.id}`, {
                            state: {
                              communityMemberStatus: data?.admin
                                ? "admincommunity"
                                : "othercommunity",
                                from:0
                            },
                          })
                        }
                      >
                        <div className="py-10" style={{}}>
                          <div
                            style={{
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              cursor: "pointer",
                            }}
                            className=" disoverListouter text-[#804C31]"
                          >
                            <img
                              src={
                                data?.communityProfileUrl
                                  ? data?.communityProfileUrl
                                  : connection
                              }
                              style={{
                                borderRadius: "150px",
                                width: "100px",
                                height: "100px",
                                marginLeft: "auto",
                                marginRight: "auto",
                                objectFit: "cover",
                              }}
                              alt=""
                            />
                            <div className="discoverUIwrap">
                              <div className="discoverthumbnailTitle">
                                {data.name}
                              </div>
                              <div className="discoverthumbnailDescription">
                                {/* <p> {data.description}</p> */}
                                <p className="text-slate-600">
                                  {data?.noOfParticipants}{" "}
                                  {data?.noOfParticipants > 1
                                    ? "members"
                                    : "member"}
                                </p>
                                {data?.requested === true &&
                                data?.member === false ? (
                                  <div className="joingroup">
                                    <Button
                                      style={{
                                        width: "fit-content",
                                        color: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        borderWidth: "2px",
                                        borderColor: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleJoinCommunity(data.id);
                                      }}
                                    >
                                      <IssuesCloseOutlined />
                                      Requested
                                    </Button>
                                  </div>
                                ) : (
                                  <div className="joingroup">
                                    <Button
                                      style={{
                                        width: "fit-content",
                                        color: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        borderWidth: "2px",
                                        borderColor: data?.requested
                                          ? "#a3c844"
                                          : "#804C31",
                                        display: "flex",
                                        alignItems: "center",
                                        opacity:(loginVia === "email" && !isEmailVerified) ? "0.65" : "1"
                                      }}
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        handleJoinCommunity(data.id);
                                      }}
                                      className="showdesktop"
                                      disabled={(loginVia === "email" && !isEmailVerified)}
                                    >
                                      <UserAddOutlined />
                                      {/* {joinStatus} */}
                                      {data?.requested
                                        ? "Requested"
                                        : "Join Group"}
                                      {/* Join Group */}
                                    </Button>
                                    <Button
                                      className="showmobile"
                                      disabled={(loginVia === "email" && !isEmailVerified)}
                                      onClick={(event) =>{
                                        event.stopPropagation();
                                        handleJoinCommunity(data.id)}
                                      }
                                      style={{opacity:(loginVia === "email" && !isEmailVerified) ? "0.65" : "1"}}
                                    >
                                      <UserAddOutlined />
                                      Join
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center pt-10 pb-10"
                    style={{
                      marginLeft: "0px",
                      marginRight: "0px",
                      justifyContent: "center",
                      marginTop: "75px",
                      marginBottom: "75px",
                    }}
                  >
                    <img
                      alt=""
                      src={image}
                      style={{ width: "100px", height: "100px" }}
                    />
                    <div className="text-gray-500 text-2xl pt-5">
                      {" "}
                      No new communities{" "}
                    </div>
                  </div>
                )}
              </div>
            </InfiniteScroll>
          </div>
        )}
      </div>
    </PullToRefreshComponent>
  );
}

export default ForYou;

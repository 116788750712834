import React, { useState, useEffect, useRef } from "react";
import { TIMEAGO } from "../../Utils/timeago";
import { request } from "../../Utils/request";
import image from "../../assets/images/imageplaceholder.png";
import CommentIcon from "../../assets/svg icons/Group 110.svg";
import FeedsComponentPopup from "../Feeds/FeedsComponentPopup";
import downArrow from "../../assets/newUiImages/downArrow.png";
import { isMobile } from "../../Utils/mobileSettings";
import { CheckBadWordsExist } from "../../Utils/checkbadWordsExist";
import { toast } from "react-toastify";
import LinkPreview from "../Feeds/LinkPreview";
import { getExpireDate } from "../../Utils/timeago";
import { videoType, imageType } from "../../Utils/VideoType";
import {
  SendOutlined,
  RightCircleOutlined,
  LeftCircleOutlined,
  LikeOutlined,
  LikeTwoTone,
  DislikeTwoTone,
  DislikeOutlined,
  ShareAltOutlined,
  MoreOutlined,
  DeleteOutlined,
  UsergroupAddOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import redflag from "../../assets/svg icons/redflag.svg";
import greyflag from "../../assets/svg icons/greyflag.svg";
import commentDisabled from "../../assets/svg icons/comments disable.svg";
import ReportPostModal from "../Feeds/ReportPostModal";
import AddIcon from "../../assets/newUiImages/addIcon.svg";
import { Input, Button, Modal, Radio, Popover, Checkbox, Spin } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import "./CommunityPosts.css";
import { imageVideoHeightCalc } from "../../Utils/imageVideoHeightCalc";
import Loader from "../Loader/Loader";
import { checkEmailVerified } from "../../Utils/checkEmailVerified";

type Props = {
  id?: string;
  posts?: any;
  from?: string;
  getCommunityPosts?: any;
  communityBlocked?: any;
  memberBlocked?: any;
  isMember?: any;
  tags?: any;
  postsOnlyByAdmin?: any;
  admin?: any;
  tagsList?: any;
  selectedTags?: any;
  setSelectedTags?: any;
  refreshPosts?: any;
  postsLoader?:any;
};

function CommunityPosts(props: Props) {
  const location = useLocation();
  const communityMemberStatus = location?.state?.communityMemberStatus;

  const [posts, setPosts] = useState<any[]>([]);
  const [loader, setLoader] = useState<Boolean>(true);
  const [toggleImgVideoPost, setToggleImgVideoPost] = useState(false);
  const [postComments, setPostComments] = useState<any[]>([]);
  const [comment, setComment] = useState<any>("");
  const [postId, setPostId] = useState<any>("");
  const [particularPost, setParticularPost] = useState<any>({});
  const [particularPostComment, setParticularPostComment] = useState<any>("");
  const [particularPostReply, setParticularPostReply] = useState<any>("");
  const [popupImgVideo, setPopupImgVideo] = useState<any>({});
  const [imgPrevNext, setImgPrevNext] = useState<any>(0);
  const [createPopup, setCreatePopup] = useState<boolean>(false);
  const [commentHasMore, setCommentHasMore] = useState<boolean>(true);
  const [commentPostId, setCommentPostId] = useState<any>("");
  const [sharePost, setSharePost] = useState<any>();
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [shareModal, setShareModal] = useState<boolean>(false);
  const [postCommentsData, setPostCommentsData] = useState<any>();
  const [showCommentsMap, setShowCommentsMap] = useState<any>({});
  const [postCommentsMap, setPostCommentsMap] = useState<any>({});
  const [commentRepliesMap, setCommentRepliesMap] = useState<{
    [key: string]: any[];
  }>({});
  const [toggleAction, setToggleAction] = useState<any>(null);
  const [replyingToCommentId, setReplyingToCommentId] = useState<any>({});
  const [currentCommentPage, setCurrentCommentPage] = useState<number>(0);
  const [currentCommentModalPage, setCurrentCommentModalPage] =
    useState<number>(0);
  const [particularPostPoll, setParticularPostPoll] = useState<any>({});
  const [remainingPollUsers, setRemainingPollUsers] = useState<any>({});
  const [showPoll, setShowPoll] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [showReportList, setShowReportList] = useState<boolean>(false);
  const [showReportPost, setShowReportPost] = useState<any>();
  const [createPostPopup, setCreatePostPopup] = useState<boolean>(false);
  const [selectedTagCategory, setSelectedTagCategory] = useState<any>("All");
  const [isSending, setIsSending] = useState(false); // State to track if the comment is being sent
  const [isReplySending, setIsReplySending] = useState(false); // State to track if the reply is being sent
  const navigate = useNavigate();
  const [isEmailVerified, setIsEmailVerified] = useState<any>()
  const loginVia = localStorage.getItem("loginInVia")

  const getEmail = async()=>{
    const isVerified = await checkEmailVerified()
    setIsEmailVerified(isVerified)
  }
  useEffect(() => {
    getEmail()
  },[])

  const handleShare = async () => {
    try {
      const requestPayload = { method: "POST" };
      const resp = await request(
        `/member/current/share/${sharePost?.postId}`,
        requestPayload
      );
      var message = {
        url: resp?.shortLink,
      };

      console.log("window?.webkit-->", window?.webkit);
      console.log(
        "window.observer.AndroidJSBridge-->",
        window?.observer?.AndroidJSBridge
      );
      console.log("window.AndroidJSBridge-->", window?.AndroidJSBridge);

      if (
        typeof window?.webkit?.messageHandlers?.nativeDelegate?.postMessage ===
        "function"
      ) {
        console.log(
          "inside typeof window.webkit?.messageHandlers?.nativeDelegate?.postMessage-->",
          typeof window.webkit?.messageHandlers?.nativeDelegate?.postMessage
        );
        window?.webkit?.messageHandlers?.observer?.postMessage(message);
      } else if (
        typeof window?.webkit?.messageHandlers?.postMessage === "function"
      ) {
        console.log(
          "typeof window.webkit?.messageHandlers?.postMessagee-->",
          typeof window?.webkit?.messageHandlers?.postMessage
        );
        window.webkit?.messageHandlers?.observer?.postMessage(message);
      } else if (
        typeof window?.AndroidJSBridge?.receiveMessage === "function"
      ) {
        console.log(
          "else of typeof window.webkit?.messageHandlers?.nativeDelegate?.postMessage-->",
          typeof window?.webkit?.messageHandlers?.nativeDelegate?.postMessage
        );
        window?.AndroidJSBridge?.receiveMessage(resp?.data?.shortLink);
      } else {
        console.log("Else looppppp....");
        window?.webkit?.messageHandlers?.observer?.postMessage(message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobilescreen = window.innerWidth < 768;
      setIsMobileView(isMobilescreen);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setPosts(props.posts);
  }, [props.posts]);

  var displayImgVideo = (postItem: any) => {
    let postData = { ...postItem };
    setPostComments([]);
    if (postItem?.combinedURLs) {
      let data = [];
      let post = postItem?.combinedURLs;
      for (let i = 0; i < post?.length; i++) {
        let format = {
          url: post[i],
          type: videoType.some((fileType) => post[i].includes(fileType))
            ? "video"
            : "photo",
          altTag: "Image Video",
        };
        data.push(format);
      }
      postData["combinedURLs"] = data;
      setPopupImgVideo(postData?.combinedURLs?.[0]);
    } else {
      setPopupImgVideo(null);
    }
    if (isMobileView) {
      navigate(`/feeds/${postItem.postId}`);
    } else {
      setParticularPost(postData);
      gettingComments(postItem?.postId);
      setPostId(postItem?.postId);
      setCurrentCommentModalPage(0);
      setToggleImgVideoPost(true);
    }
  };

  const displaySharePost = (postItem: any, from?: any) => {
    let postData = { ...postItem };
    if (postItem.member) {
      if (postItem?.metaData) {
        let data = [];
        let post = postItem?.metaData;
        for (let i = 0; i < post?.length; i++) {
          let format = {
            url: post[i],
            type: videoType.some((fileType) => post[i].includes(fileType))
              ? "video"
              : "photo",
            altTag: "Image Video",
          };
          data.push(format);
        }
        postData["metaData"] = data;
        // setPopupImgVideo(postData?.metaData?.[0]);
      }
      setSharePost(postData);
      isMobileView ? setShareModal(true) : setCreatePopup(true);
      setToggleImgVideoPost(false);
    }
  };
  const loadMoreComments = () => {
    setCurrentCommentPage((Page) => Page + 1);
  };
  const loadMoreModalComments = () => {
    setCurrentCommentModalPage((Page) => Page + 1);
  };

  const gettingPostComments = async (postID: any) => {
    try {
      const response = await request(
        `/member/current/comments/${postID}?page=${
          currentCommentPage || 0
        }&size=5`
      );
      if (response?.content?.length > 0) {
        setPostCommentsMap((prevComments: any) => ({
          ...prevComments,
          [postID]:
            currentCommentPage === 0
              ? response.content
              : [...(prevComments[postID] || []), ...response.content],
        }));
      }
    } catch (error) {
      // Handle error
    }
  };

  const gettingPostReply = async (commentID: any) => {
    try {
      const response = await request(
        `/member/current/comment/${commentID}/reply`
      );
      if (response?.length > 0) {
        setCommentRepliesMap((prevComments: any) => ({
          ...prevComments,
          [commentID]: response,
        }));
      }
    } catch (error) {
      // Handle error
    }
  };

  const handleViewReplies = async (commentID: any) => {
    if (!commentRepliesMap[commentID]) {
      await gettingPostReply(commentID);
    }
  };

  // const gettingPostComments = async (postID: any) => {
  //   try {
  //     const response = await request(
  //       `/member/current/comments/${postID}?page=${
  //         currentCommentPage || 0
  //       }&size=5`
  //     );
  //     if (response?.content?.length > 0) {
  //       const commentsWithReplies = await Promise.all(
  //         response?.content?.map(async (comment: any) => {
  //           if (comment?.noOfReplies > 0) {
  //             const replies = await gettingPostReply(comment?.commentId);
  //             return { ...comment, replies };
  //           }
  //           return comment;
  //         })
  //       );

  //       setPostCommentsMap((prevComments: any) => ({
  //         ...prevComments,
  //         [postID]:
  //           currentCommentPage === 0
  //             ? commentsWithReplies
  //             : [...(prevComments[postID] || []), ...commentsWithReplies],
  //       }));
  //     }
  //   } catch (error) {
  //     // Handle error
  //   }
  // };
  // const gettingPostReply = async (commentID: any) => {
  //   try {
  //     const response = await request(
  //       `/member/current/comment/${commentID}/reply`
  //     );
  //     if (response?.length > 0) {
  //       setCommentRepliesMap((prevComments: any) => ({
  //         ...prevComments,
  //         [commentID]: response,
  //       }));
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    if (commentPostId?.length > 0) gettingPostComments(commentPostId);
  }, [currentCommentPage]);
  useEffect(() => {
    if (postId?.length > 0) gettingComments(postId);
  }, [currentCommentModalPage]);

  const sendPostComments = async () => {
    if (particularPostComment) {
      setIsSending(true); // Disable input and button
      const abusiveResult = await CheckBadWordsExist(particularPostComment);

      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                postId: commentPostId,
                content: particularPostComment,
              }),
            };

            const response = await request("/member/current/comment", body);
            if (response?.commentId) {
              let dt: any = "2023-09-11T09:53:41.44050308";
              let newParticularPost = postCommentsData;
              newParticularPost = {
                ...newParticularPost,
                noOfComments: response?.noOfCommentsOfPost,
              };

              let newPost = posts;
              let findIndex = posts.findIndex(
                (item) => item.postId === response.postId
              );
              newPost[findIndex] = {
                ...newPost[findIndex],
                noOfComments: response?.noOfCommentsOfPost,
              };

              setPosts(newPost);
            }
            setParticularPostComment("");
            setCurrentCommentPage(0);
            gettingPostComments(commentPostId);
          } catch (error) {
            console.log("Error===>", error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsSending(false); // Re-enable input and button after API call is finished
    }
  };
  const sendReply = async (
    commentId: string,
    replyText: string,
    postId: string
  ) => {
    if (replyText) {
      const abusiveResult = await CheckBadWordsExist(replyText);
      setIsReplySending(true);
      if (abusiveResult?.status === true) {
        console.log(abusiveResult?.status === true);
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          console.log("IN TRUE");
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                commentId: commentId,
                postId: postId,
                content: replyText,
                replyType: "FIRST_REPLY",
              }),
            };
            const response = await request("/member/current/reply", body);
            if (response?.commentId) {
              let dt: any = "2023-09-11T09:53:41.44050308";
              let newPost = posts;
              let findIndex = posts.findIndex(
                (item) => item.postId === postCommentsData?.postId
              );
              newPost[findIndex] = {
                ...newPost[findIndex],
                noOfReplies: newPost[findIndex]?.noOfReplies + 1,
              };
              setPosts(newPost);
              setParticularPostReply("");
              gettingPostReply(commentId);
            }
          } catch (error) {
            console.log("Error===>", error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsReplySending(false);
    }
  };

  const gettingComments = async (postID: any) => {
    try {
      const response = await request(
        `/member/current/comments/${postID}?page=${
          currentCommentModalPage || 0
        }&size=5`
      );
      if (response?.content?.length > 0) {
        setPostComments((prevComments: any) => ({
          ...prevComments,
          [postID]:
            currentCommentModalPage === 0
              ? response.content
              : [...(prevComments[postID] || []), ...response.content],
        }));
        // setPostComments(response?.content);
        // const commentsWithReplies = await Promise.all(
        //   response?.content?.map(async (comment: any) => {
        //     if (comment?.noOfReplies > 0) {
        //       const replies = await gettingPostReply(comment?.commentId);
        //       return { ...comment, replies };
        //     }
        //     return comment;
        //   })
        // );

        // setPostComments((prevComments: any) => ({
        //   ...prevComments,
        //   [postID]:
        //     currentCommentModalPage === 0
        //       ? commentsWithReplies
        //       : [...(prevComments[postID] || []), ...commentsWithReplies],
        // }));
        setCommentHasMore(true);
      } else {
        setCommentHasMore(false);
      }
    } catch (error) {}
  };

  const handleModalViewReplies = async (commentID: any) => {
    if (!commentRepliesMap[commentID]) {
      await gettingPostReply(commentID);
    }
  };

  const toggleComments = (postItem: any) => {
    let postData = { ...postItem };
    setCurrentCommentPage(0);
    setShowCommentsMap((prevShowCommentsMap: any) => ({
      ...prevShowCommentsMap,
      [postItem?.postId]: !prevShowCommentsMap[postItem?.postId],
    }));
    setPostCommentsData(postData);
    gettingPostComments(postItem?.postId);
    setCommentPostId(postItem?.postId);
  };
  const replyToComment = (commentId: string) => {
    setReplyingToCommentId(commentId); // Store the comment ID being replied to
  };

  const sendComments = async () => {
    if (comment) {
      setIsSending(true); // Disable input and button
      const abusiveResult = await CheckBadWordsExist(comment);

      if (abusiveResult?.status === true) {
        if (!abusiveResult?.profanity && !abusiveResult?.words?.length) {
          try {
            const body = {
              method: "POST",
              body: JSON.stringify({
                postId: postId,
                content: comment,
              }),
            };

            const response = await request("/member/current/comment", body);
            if (response?.commentId) {
              let dt: any = "2023-09-11T09:53:41.44050308";
              let newParticularPost = particularPost;
              newParticularPost = {
                ...newParticularPost,
                noOfComments: response?.noOfCommentsOfPost,
              };

              let newPost = posts;
              let findIndex = posts.findIndex(
                (item) => item.postId === response.postId
              );
              newPost[findIndex] = {
                ...newPost[findIndex],
                noOfComments: response?.noOfCommentsOfPost,
              };

              setPosts(newPost);
            }
            console.log("NEW POST!", posts);
            setComment("");
            setCurrentCommentModalPage(0);
            gettingComments(postId);
          } catch (error) {
            console.log("Error===>", error);
          }
        } else if (abusiveResult?.profanity) {
          toast.error("Prohibited from generating abusive content");
        }
      } else {
        toast.error(abusiveResult?.message);
      }
      setIsSending(true); // Disable input and button
    }
  };

  const handleCancel = () => {
    setPostComments([]);
    setPostId("");
    setComment("");
    setParticularPost({});
    setPopupImgVideo(null);
    setImgPrevNext(0);
    setCurrentCommentModalPage(0);
    setToggleImgVideoPost(false);
  };

  useEffect(() => {
    let dt = particularPost?.combinedURLs?.[imgPrevNext];
    setPopupImgVideo(dt);
  }, [imgPrevNext]);

  const LikeAndDislikeCall = async (
    type: string,
    data: any,
    index?: number
  ) => {
    const post = data;
    const status = post?.currentSentimentStatus;
    const body = {
      method: "POST",
      body: JSON.stringify({
        communityId: post?.communityId,
        sentimentType:
          status === null ? type : status === type ? "NONPARTICIPATING" : type,
      }),
    };

    const resp = await request(
      `/member/current/post/${post?.postId}/sentiment`,
      body
    );
    if (resp?.id) {
      let newParticularPost = particularPost;
      newParticularPost = {
        ...newParticularPost,
        currentSentimentStatus: resp?.sentimentType,
        noOfDislikes: resp?.noOfDislikes,
        noOfLikes: resp?.noOfLikes,
      };

      let newPosts = posts;
      let findIndex = posts.findIndex((item) => item.postId === post.postId);
      newPosts[findIndex] = {
        ...newPosts[findIndex],
        currentSentimentStatus: resp?.sentimentType,
        noOfDislikes: resp?.noOfDislikes,
        noOfLikes: resp?.noOfLikes,
      };
      setPosts(newPosts);
      setParticularPost(newParticularPost);
    }
  };

  const LikeAndDisLikeComponent = (
    post: any,
    previewPopup?: boolean,
    index?: number
  ) => {
    return (
      <>
        <div className="flex flex-row gap-1 items-center mr-6">
          <div className="flex gap-2">
            {previewPopup && (
              <div className="text-base ml-2">
                {" "}
                {post?.noOfLikes > 0 ? post?.noOfLikes : ""}
              </div>
            )}
            {post?.member ? (
              !post?.communityBlocked && !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                post?.currentSentimentStatus === "LIKE" ? (
                  <LikeTwoTone
                    onClick={(event) => {
                      LikeAndDislikeCall("NONPARTICIPATING", post, index);
                      event?.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                ) : (
                  <LikeOutlined
                    onClick={(event) => {
                      LikeAndDislikeCall("LIKE", post, index);
                      event?.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                )
              ) : (
                <LikeOutlined
                  disabled
                  className="like-share-icon  disabled:opacity-15"
                  style={{ color: "gray" }}
                />
              )
            ) : (
              <LikeOutlined
                disabled
                className="like-share-icon  disabled:opacity-15"
                style={{ color: post?.member ? "black" : "gray" }}
              />
            )}
            {previewPopup ? <span className="mr-4">Like</span> : ""}
          </div>
          {!previewPopup && (
            <div
              className={
                !post?.communityBlocked && !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                  ? "text-base mr-5"
                  : "text-base mr-5 text-gray-500"
              }
            >
              {" "}
              {post?.noOfLikes > 0 ? post?.noOfLikes : ""}
            </div>
          )}
          <div className="flex gap-2">
            {post?.member ? (
              !post?.communityBlocked && !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                post?.currentSentimentStatus === "DISLIKE" ? (
                  <DislikeTwoTone
                    onClick={(event) => {
                      LikeAndDislikeCall("NONPARTICIPATING", post, index);
                      event.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                ) : (
                  <DislikeOutlined
                    onClick={(event) => {
                      LikeAndDislikeCall("DISLIKE", post, index);
                      event.stopPropagation();
                    }}
                    className="like-share-icon"
                  />
                )
              ) : (
                <DislikeOutlined
                  disabled
                  className="like-share-icon"
                  style={{ color: "gray" }}
                />
              )
            ) : (
              <DislikeOutlined
                disabled
                className="like-share-icon"
                style={{ color: post?.member ? "black" : "gray" }}
              />
            )}
            {previewPopup ? <span>Dislike</span> : ""}
          </div>
        </div>
      </>
    );
  };

  function getVideoId(url: any) {
    const patterns = [
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch(?:\/|.+?)?v=|embed\/|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/live\/([a-zA-Z0-9_-]{11}).*/,
      /(?:https?:\/\/)?(?:www\.)?youtube\.com\/shorts\/([a-zA-Z0-9_-]{11}).*/,
    ];

    for (const pattern of patterns) {
      const match = url.match(pattern);
      if (match && match[1]) {
        return match[1];
      }
    }

    return null;
  }
  function postlink(content: any) {
    // const youtubeUrlPattern =
    //   /(?<!href="|target="_blank">)https?:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+(?:&[\w=%]*)?/g;

    // // Remove YouTube URLs from the input string
    // const processedString = content?.replace(youtubeUrlPattern, "");
    // console.log(processedString);

    return (
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        // key={index}
        className="rich-html-text"
      />
    );
  }

  const handleCheckboxChange = async (ind: any, data: any) => {
    const newData = [...posts];
    const status = !data?.redFlag;
    newData[ind].redFlag = status;
    setPosts(newData);
    try {
      const payload = { method: "PATCH", body: JSON.stringify({}) };
      const response = await request(
        `/member/current/community/admin/post/${data?.postId}?redFlag=${status}`,
        payload
      );
      if (response) {
        status
          ? toast.error(
              "Post red flaged successfully! Members will not be able to view this post."
            )
          : toast.success(
              "Post restored successfully! Members can now view this post."
            );
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again later.");
    }
  };

  const deletePost = async (ind: any, data: any) => {
    toggleActionContainer(ind, false);
    try {
      const payload = { method: "PATCH", body: JSON.stringify({}) };
      const response = await request(
        `/member/current/post/${data?.postId}?archive=true`,
        payload
      );
      if (response) {
        console.log("response=====>", response);
        let newData = [...posts];
        newData.splice(ind, 1);
        setPosts(newData);
        toast.success("Post deleted successfully!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleActionContainer = (index: any, visible: any) => {
    if (visible) setToggleAction(index);
    else setToggleAction(null);
  };

  const onViewMoreClick = (post: any, postItem: any) => {
    let postCopy = [...posts];
    const findIndex = postCopy.findIndex((item) => item.postId === post.postId);
    postCopy[findIndex] = {
      ...postCopy[findIndex],
      viewMore: true,
      pollOutput: {
        ...postCopy[findIndex].pollOutput,
        viewMore: true,
      },
    };
    setPosts(postCopy);
  };

  const onRadioButtonChange = async (
    postId: string,
    optionValue: any,
    participated: any,
    type: string
  ) => {
    if (type === "singleSelect" && participated) return;
    const requestPayload = {
      method: "POST",
      body: JSON.stringify({
        postId,
        option: optionValue,
      }),
    };
    const typeOfUrl =
      type === "singleSelect" ? "fill" : participated ? "remove" : "fill";
    const resp = await request(
      `/member/current/poll/${typeOfUrl}/${postId}?option=${optionValue}`,
      requestPayload
    );
    let postCopy = [...posts];
    const findIndex = postCopy.findIndex((item) => item.postId === resp.postId);
    postCopy[findIndex] = {
      ...postCopy[findIndex],
      pollOutput: resp?.pollOutput,
    };
    setPosts(postCopy);
  };

  const handleImageLoad = (
    event: React.SyntheticEvent<HTMLImageElement, Event>,
    postId: string
  ) => {
    const { naturalWidth, naturalHeight } = event.currentTarget;
    imageVideoHeightCalc(naturalWidth, naturalHeight, postId);
  };

  const handleVideoLoadedMetadata = (
    event: React.SyntheticEvent<HTMLVideoElement>,
    postId: any
  ) => {
    const { videoWidth, videoHeight } = event.currentTarget;
    imageVideoHeightCalc(videoWidth, videoHeight, postId);
  };
  const handleIframeLoad = (
    event: React.SyntheticEvent<HTMLIFrameElement, Event>,
    postId: any
  ) => {
    const iframe = event.currentTarget;
    const { contentWindow } = iframe;

    if (contentWindow) {
      const { innerWidth, innerHeight } = contentWindow;
      imageVideoHeightCalc(innerWidth, innerHeight, postId);
    }
  };
  const displayPollData = (post: any) => {
    let postData = { ...post };
    setParticularPostPoll(postData);
    const resetRemainingPollUsers: any = {};
    post?.pollOutput?.options?.forEach((poll: any) => {
      resetRemainingPollUsers[poll.option] = null;
    });
    setRemainingPollUsers(resetRemainingPollUsers);
    setShowPoll(true);
  };
  const handleViewAllUsers = (selectedOption: any) => {
    const usersVotedForOption = particularPostPoll?.pollOutput?.options?.find(
      (poll: any) => poll.option === selectedOption
    )?.users;
    if (usersVotedForOption) {
      setRemainingPollUsers({
        ...remainingPollUsers,
        [selectedOption]: usersVotedForOption.slice(3),
      });
    }

    console.log(
      `Users who voted for "${selectedOption}":`,
      usersVotedForOption
    );
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTagContainerRef = useRef<HTMLDivElement>(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" }); // Adjust the value as needed
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" }); // Adjust the value as needed
    }
  };

  const scrollTagLeft = () => {
    if (scrollTagContainerRef.current) {
      scrollTagContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      }); // Adjust the value as needed
    }
  };

  const scrollTagRight = () => {
    if (scrollTagContainerRef.current) {
      scrollTagContainerRef.current.scrollBy({ left: 200, behavior: "smooth" }); // Adjust the value as needed
    }
  };

  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isTagsOverflowing, setIsTagsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (scrollContainerRef.current) {
        const { scrollWidth, clientWidth } = scrollContainerRef.current;
        setIsOverflowing(scrollWidth > clientWidth);
      }
    };

    const handleResize = () => {
      setTimeout(checkOverflow, 100);
    };

    // Check overflow on component mount
    handleResize();

    // Check overflow when the window resizes
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props?.tagsList]);

  useEffect(() => {
    const checkOverflow = () => {
      console.log("HERE");
      if (scrollTagContainerRef.current) {
        const { scrollWidth, clientWidth } = scrollTagContainerRef.current;
        console.log("scrollWidth:", scrollWidth, "clientWidth:", clientWidth);
        setIsTagsOverflowing(scrollWidth > clientWidth);
      }
    };

    const handleResize = () => {
      setTimeout(checkOverflow, 100);
    };

    // Check overflow on component mount
    handleResize();

    // Check overflow when the window resizes
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [selectedTagCategory]);

  return (
    <div>
    {  props.postsLoader ? (
                      <div className="w-full flex justify-center">
                        <Loader />
                      </div>
                    ) : 
      props?.posts?.length > 0 ? (
        <div className="mt-5">
          <div className="postfeedsContainer">
            <div className="headingFeedLabel">
              <div className="headingContainerWidth">
                <div className="headtop">
                  {props?.from !== "Profile" ? "Categories" : "Posts"}
                </div>
                {props?.from !== "Profile" && (
                  <div className="md:pr-2">
                    <div
                      className="relative flex tagsContainerWidth"
                      // className="relative lg:max-w-4xl max-w-sm md:max-w-md"
                    >
                      {isOverflowing && (
                        <>
                          <button
                            onClick={scrollLeft}
                            className="absolute left-0 z-10 px-3 py-2 bg-gradient-to-r from-gray-300 to-gray-100/90 shadow-md hover:shadow-lg rounded-l-lg  hidden md:block"
                          >
                            <i className="bi bi-chevron-left"></i>
                          </button>
                          <div>
                            <button
                              onClick={scrollRight}
                              // style={{backgroundImage: "linear-gradient(to left, #fff 20%, rgba(255, 255, 255, 0) 80%)"
                              // }}
                              className="absolute right-0 z-10 px-3 py-2 bg-gradient-to-l from-gray-300 to-gray-100/90 shadow-md hover:shadow-lg rounded-r-lg  hidden md:block"
                            >
                              <i className="bi bi-chevron-right"></i>
                            </button>
                          </div>
                        </>
                      )}

                      <div
                        ref={scrollContainerRef}
                        className={`flex flex-row overflow-x-auto scrollbar-hide ${
                          isOverflowing && "md:px-8 md:ml-2 md:mx-3 "
                        }   rounded-lg `}
                      >
                        {Object.keys(props?.tagsList)?.map(
                          (category: any, index: any) => {
                            // console.log(tag);
                            return (
                              <>
                                <div
                                  key={index}
                                  className="flex-shrink-0 cursor-pointer"
                                  onClick={() => {
                                    if (selectedTagCategory !== category) {
                                      setSelectedTagCategory(category);
                                      props?.setSelectedTags(
                                        props?.tagsList[category]
                                      );
                                    } else {
                                      setSelectedTagCategory("All");
                                      props?.setSelectedTags([]);
                                    }
                                  }}
                                >
                                  <div
                                    className={
                                      selectedTagCategory === category
                                        ? "flex flex-row bg-[#67702E] tagCategoryList truncate text-white"
                                        : "flex flex-row tagCategoryList truncate bg-[#ffffff] text-black"
                                    }
                                  >
                                    {category}
                                  </div>
                                </div>
                              </>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {props?.tagsList[selectedTagCategory]?.length > 0 && (
                      <div
                        className="relative flex tagsContainerWidth pt-2"
                        // className="relative lg:max-w-4xl max-w-sm md:max-w-md"
                      >
                        {isTagsOverflowing && (
                          <>
                            <button
                              onClick={scrollTagLeft}
                              className="absolute left-0 z-10 px-2 py-1 bg-gradient-to-r from-gray-300 to-gray-100/90 shadow-md hover:shadow-lg rounded-l-2xl  hidden md:block"
                            >
                              <i className="bi bi-chevron-left"></i>
                            </button>
                            <div>
                              <button
                                onClick={scrollTagRight}
                                // style={{backgroundImage: "linear-gradient(to left, #fff 20%, rgba(255, 255, 255, 0) 80%)"
                                // }}
                                className="absolute right-0 z-10 px-2 py-1 bg-gradient-to-l from-gray-300 to-gray-100/90 shadow-md hover:shadow-lg rounded-r-2xl  hidden md:block"
                              >
                                <i className="bi bi-chevron-right"></i>
                              </button>
                            </div>
                          </>
                        )}

                        <div
                          ref={scrollTagContainerRef}
                          className={`flex flex-row overflow-x-auto scrollbar-hide ${
                            isTagsOverflowing && "md:px-8 md:ml-2 md:mx-3"
                          }   rounded-lg `}
                        >
                          {props?.tagsList[selectedTagCategory]?.map(
                            (tag: any, index: any) => {
                              // console.log(tag);
                              return (
                                <>
                                  <div
                                    key={index}
                                    className="flex-shrink-0 cursor-pointer"
                                    onClick={() => {
                                      if (props.selectedTags !== tag) {
                                        props?.setSelectedTags([tag]);
                                      } else {
                                        props?.setSelectedTags(
                                          props?.tagsList[selectedTagCategory]
                                        );
                                      }
                                    }}
                                  >
                                    <div
                                      className={
                                        props.selectedTags?.length === 1 &&
                                        props.selectedTags[0] === tag
                                          ? "flex flex-row bg-[#67702E] tagList truncate text-white"
                                          : "flex flex-row tagList truncate bg-[#f5f5f5] text-black"
                                      }
                                    >
                                      {tag}
                                    </div>
                                  </div>
                                </>
                              );
                            }
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {props?.from !== "Profile" &&
                //  (popupCommunityList?.some((community:any) => community.communityId === props?.id)) &&
                // props?.isMember &&
                !props?.communityBlocked &&
                !props?.memberBlocked && !(loginVia === "email" && !isEmailVerified) &&
                (props?.admin || // Admin can always see the button
                  (props?.isMember && !props?.postsOnlyByAdmin)) && ( // Members can see the button if postsOnlyByAdmin is false
                  <div>
                    <button
                      className="postcreateButton showDesktop"
                      onClick={() => {
                        setCreatePostPopup(true);
                      }}
                    >
                      <div className="plusButtonSize">+</div>
                    </button>
                  </div>
                )}
            </div>
            {posts?.length > 0 &&
              posts?.map((post: any, index) => (
                <div
                  className="postCard "
                  style={{ cursor: "pointer" }}
                  key={index}
                >
                  <div className="flex items-center justify-between">
                    <div className="dpandname">
                      {post?.userProfileUrl ? (
                        <div className="justify-center overflow-hidden rounded-full dark:[#804C31] postProfileImage">
                          <span className="">
                            <img src={post?.userProfileUrl} alt="" />
                          </span>
                        </div>
                      ) : (
                        <div
                          className="capitalize relative inline-flex items-center justify-center w-10 h-10 overflow-hidden  rounded-full"
                          style={{ backgroundColor: post.profileColorCode }}
                        >
                          <span className="font-medium text-white dark:text-gray-300">
                            {post?.userName?.substring(0, 1)}
                          </span>
                        </div>
                      )}

                      {isMobileView ? (
                        <div className="flex flex-col justify-center">
                          <div className="flex items-center">
                            <span className="posttitle capitalize">
                              {post?.userName}
                            </span>
                            {props?.from === "Profile" ? (
                              <>
                                <span className="ml-1">&#8594;</span>
                                <span className="posttitle">
                                  {post?.communityName}
                                </span>
                              </>
                            ) : null}
                          </div>
                          <div className="text-xs ml-3">
                            <TIMEAGO timestamp={post?.createdAt} />
                          </div>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <span className="posttitle capitalize">
                            {post?.userName}
                          </span>
                          {props?.from === "Profile" ? (
                            <>
                              <span className="ml-1">&#8594;</span>
                              <span className="posttitle">
                                {post?.communityName}
                              </span>
                            </>
                          ) : null}
                        </div>
                      )}
                    </div>

                    {communityMemberStatus === "admincommunity"
                      ? !post?.communityBlocked &&
                        !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) && (
                          <div className="dpandname">
                            <div
                              className={`flag-button r ${
                                post?.redFlag ? "move-right" : ""
                              }`}
                              id={`flagpost_${index}`}
                            >
                              <input
                                type="checkbox"
                                className="flag-checkbox"
                                checked={post?.redFlag}
                                onChange={() =>
                                  handleCheckboxChange(index, post)
                                }
                              />
                              <div className="flagknobs"></div>
                              <div className="flaglayer"></div>
                              <div className="flagimg">
                                <img
                                  src={post?.redFlag ? redflag : greyflag}
                                  style={{ width: "15px", height: "15px" }}
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                        )
                      : post?.currentUserCreated
                      ? !post?.communityBlocked &&
                        !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) && (
                          <div className="dpandname">
                            <Popover
                              id={"Popover_" + index}
                              content={
                                <Button
                                  className="post-del-btn text-left w-full"
                                  icon={<DeleteOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deletePost(index, post);
                                  }}
                                >
                                  Delete
                                </Button>
                              }
                              trigger="click"
                              open={toggleAction === index}
                              placement="bottomRight"
                              onOpenChange={(visible) =>
                                toggleActionContainer(index, visible)
                              }
                              key={index}
                            >
                              <div className="post-action-btn cursor-pointer">
                                <MoreOutlined
                                  style={{
                                    fontSize: "21px",
                                    transform: "rotate(90deg)",
                                  }}
                                />
                              </div>
                            </Popover>
                          </div>
                        )
                      : !post?.communityBlocked &&
                        !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) && (
                          <div className="dpandname">
                            <Popover
                              id={"Popover_" + index}
                              content={
                                <Button
                                  className="post-del-btn text-left w-full"
                                  icon={<WarningOutlined />}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setPostId(post?.postId);
                                    setShowReportModal(true);
                                  }}
                                >
                                  Report
                                </Button>
                              }
                              trigger="click"
                              open={toggleAction === index && !showReportModal}
                              placement="bottomRight"
                              onOpenChange={(visible) =>
                                toggleActionContainer(index, visible)
                              }
                              key={index}
                            >
                              <div className="post-action-btn cursor-pointer">
                                <MoreOutlined
                                  style={{
                                    fontSize: "21px",
                                    transform: "rotate(90deg)",
                                  }}
                                />
                              </div>
                            </Popover>
                          </div>
                        )}
                  </div>

                  {post?.content && (
                    <div
                      className="postContent postActualContent flex flex-col"
                      onClick={(event) => {
                        displayImgVideo(post);
                        event?.stopPropagation();
                      }}
                    >
                      <div className="flex flex-row tagContainer">
                        {post?.tags
                          ?.filter(
                            (postTag: any) =>
                              postTag?.toLowerCase() !== "general"
                          )
                          ?.map((tag: any, index: any) => (
                            <div key={index} className="flex flex-row tag">
                              {tag}
                            </div>
                          ))}
                      </div>
                      {postlink(post?.content)}
                    </div>
                  )}
                  {post.type === "POLL" ? (
                    <div className="postContent">
                      <div className="pollContentContainer">
                        <div>
                          <p className="postOptionContent text-xl font-bold">
                            {post?.pollOutput?.question}
                          </p>
                          <p className="postOptionContent font-normal">
                            {post?.pollOutput?.comment}
                          </p>
                        </div>
                        <div
                          className="poll-voting-options"
                          style={{
                            height: post?.viewMore
                              ? "400px"
                              : post?.pollOutput?.options?.length === 2
                              ? "200px"
                              : "250px",
                            overflow: post?.viewMore ? "auto" : "hidden",
                          }}
                        >
                          {post?.pollOutput?.selectTypeEnum ===
                          "MULTI_SELECT" ? (
                            <div className="radio-options-group">
                              {post?.pollOutput?.options.map(
                                (pollItem: any) => (
                                  <div
                                    onClick={(e) => {
                                      if (
                                        post?.pollOutput?.pollStatusEnum !==
                                          "EXPIRED" &&
                                        !post?.communityBlocked &&
                                        !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                      ) {
                                        e.stopPropagation();
                                        onRadioButtonChange(
                                          post?.postId,
                                          pollItem?.option,
                                          pollItem?.participated,
                                          "multiSelect"
                                        );
                                      } else {
                                        // toast.error('')
                                      }
                                      console.log("i am div onclick");
                                    }}
                                    className={`pollOptions ${
                                      pollItem?.participated
                                        ? "option-selected"
                                        : "unSelect"
                                    }`}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      cursor:
                                        post?.pollOutput?.pollStatusEnum !==
                                        "EXPIRED"
                                          ? "pointer"
                                          : "auto",
                                    }}
                                  >
                                    {post?.pollOutput?.pollStatusEnum !==
                                      "EXPIRED" &&
                                    !post?.communityBlocked &&
                                    !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                                      <div>
                                        <Checkbox
                                          style={{
                                            display: "hidden",
                                          }}
                                          className="radio-option"
                                          checked={pollItem?.participated}
                                          onChange={(e) => {
                                            console.log(
                                              "check box onchange",
                                              e.target.checked
                                            );
                                          }}
                                        >
                                          <div className="testing">
                                            <div>
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                {pollItem?.option}
                                              </span>
                                            </div>
                                          </div>
                                        </Checkbox>
                                      </div>
                                    ) : (
                                      <div>
                                        <div
                                          style={{
                                            display: "hidden",
                                          }}
                                          className="radio-option"
                                        >
                                          <div className="testing">
                                            <div>
                                              <span>{pollItem?.option}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {post?.pollOutput?.pollStatusEnum ===
                                      "EXPIRED" ||
                                    post?.pollOutput?.pollTypeEnum ===
                                      "OPEN" ? (
                                      <div className="text-[18px] pr-3 text-[#808080] font-medium">
                                        <span>
                                          {pollItem?.votes}{" "}
                                          {pollItem?.votes === 1
                                            ? "vote"
                                            : "votes"}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )
                              )}
                            </div>
                          ) : (
                            <Radio.Group
                              className="radio-options-group"
                              value={
                                post?.pollOutput?.options?.find(
                                  (item: any) => item?.participated
                                )?.option || ""
                              }
                            >
                              {post?.pollOutput?.options.map(
                                (pollItem: any) => (
                                  <div
                                    onClick={(e) => {
                                      if (
                                        post?.pollOutput?.pollStatusEnum !==
                                          "EXPIRED" &&
                                        !post?.communityBlocked &&
                                        !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                      ) {
                                        e.stopPropagation();
                                        onRadioButtonChange(
                                          post?.postId,
                                          pollItem?.option,
                                          pollItem?.participated,
                                          "singleSelect"
                                        );
                                      } else {
                                        // toast.error('')
                                      }
                                    }}
                                    className={`pollOptions ${
                                      pollItem?.participated
                                        ? "option-selected"
                                        : "unSelect"
                                    }`}
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      cursor:
                                        post?.pollOutput?.pollStatusEnum !==
                                        "EXPIRED"
                                          ? "pointer"
                                          : "auto",
                                    }}
                                  >
                                    {post?.pollOutput?.pollStatusEnum !==
                                      "EXPIRED" &&
                                    !post?.memberBlocked &&
                                    !post?.communityBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                                      <div>
                                        <Radio
                                          style={{
                                            display: "hidden",
                                          }}
                                          className="radio-option"
                                          value={pollItem?.option}
                                        >
                                          <div className="testing">
                                            <div>
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                }}
                                              >
                                                {pollItem?.option}
                                              </span>
                                            </div>
                                          </div>
                                        </Radio>
                                      </div>
                                    ) : (
                                      <div>
                                        <div
                                          style={{
                                            display: "hidden",
                                          }}
                                          className="radio-option"
                                        >
                                          <div className="testing">
                                            <div>
                                              <span>{pollItem?.option}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    {!post?.memberBlocked &&
                                    !post?.communityBlocked && !(loginVia === "email" && !isEmailVerified) &&
                                    (post?.pollOutput?.pollStatusEnum ===
                                      "EXPIRED" ||
                                      post?.pollOutput?.pollTypeEnum ===
                                        "OPEN") ? (
                                      <div className="text-[18px] pr-3 text-[#808080] font-medium">
                                        <span>
                                          {pollItem?.votes}{" "}
                                          {pollItem?.votes === 1
                                            ? "vote"
                                            : "votes"}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                )
                              )}
                            </Radio.Group>
                          )}
                        </div>

                        {post?.pollOutput?.options?.length > 3 &&
                          !post?.viewMore && (
                            <div
                              className="text-[#06be51] text-lg font-medium"
                              onClick={() => {
                                onViewMoreClick(
                                  post,
                                  post?.pollOutput?.options
                                );
                              }}
                            >
                              {post?.pollOutput?.options?.length - 3 === 1
                                ? `+${
                                    post?.pollOutput?.options?.length - 3
                                  } More option`
                                : ` +${
                                    post?.pollOutput?.options?.length - 3
                                  } More options`}
                            </div>
                          )}

                        <div
                          className="flex justify-between items-center postOptionContent mt-2"
                          style={{ cursor: "context-menu" }}
                        >
                          <p className="md:text-base text-sm font-semibold">
                            {post?.pollOutput?.pollStatusEnum === "ACTIVE"
                              ? `Poll Expiry :  ${getExpireDate(
                                  post?.pollOutput?.expiryDate
                                )}`
                              : "Poll Expired"}
                          </p>
                          {(post?.pollOutput?.pollStatusEnum === "EXPIRED" ||
                            post?.pollOutput?.pollTypeEnum === "OPEN" ||
                            (post?.pollOutput?.pollTypeEnum === "HIDDEN" &&
                              post?.pollOutput?.viewVoters)) && (
                            <span
                              className="text-[#06be51] md:text-lg text-sm font-medium"
                              style={{
                                cursor: post?.pollOutput?.viewVoters
                                  ? "pointer"
                                  : "context-menu",
                              }}
                              onClick={() =>
                                post?.pollOutput?.viewVoters &&
                                !post?.communityBlocked &&
                                !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                  ? displayPollData(post)
                                  : null
                              }
                            >
                              Total votes : {post?.pollOutput?.totalVotes}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="postContentWrapper"
                      style={{
                        display: post?.combinedURLs?.length ? "block" : "none",
                      }}
                      onClick={(event) => {
                        displayImgVideo(post);
                        event?.stopPropagation();
                      }}
                    >
                      <div className="postsImage">
                        {post?.combinedURLs?.length && (
                          <div
                            className="flex gap-2"
                            id={`post_imgvideo_container_${post?.postId}`}
                          >
                            {post?.combinedURLs?.map(
                              (data: any, index: any) =>
                                index <= (isMobile ? 1 : 2) && (
                                  <div
                                    id={`post_img_video_${post?.postId}`}
                                    style={{
                                      position: "relative",
                                      width:
                                        post?.combinedURLs?.length >=
                                        (isMobile ? 2 : 3)
                                          ? `calc(100% / ${isMobile ? 2 : 3})`
                                          : `calc(100% / ${post?.combinedURLs?.length})`,
                                      height:
                                        post?.combinedURLs?.length > 1
                                          ? isMobile
                                            ? "250px"
                                            : "300px"
                                          : "auto",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                    key={index}
                                  >
                                    {videoType.some((fileType) =>
                                      data?.includes(fileType)
                                    ) ? (
                                      <video
                                        id={`post_image_${post?.postId}`}
                                        className="video"
                                        controls
                                        onLoadedMetadata={(e) =>
                                          post?.combinedURLs?.length === 1
                                            ? handleVideoLoadedMetadata(
                                                e,
                                                post?.postId
                                              )
                                            : {}
                                        }
                                        style={{
                                          width: "100%",
                                          backgroundColor: "black",
                                        }}
                                      >
                                        <source src={data} type="video/mp4" />
                                      </video>
                                    ) : imageType.some((fileType) =>
                                        data?.includes(fileType)
                                      ) ? (
                                      <img
                                        id={`post_image_${post?.postId}`}
                                        onLoad={(e) =>
                                          post?.combinedURLs?.length === 1
                                            ? handleImageLoad(e, post?.postId)
                                            : {}
                                        }
                                        className="image"
                                        src={data}
                                        alt=""
                                        style={{
                                          borderRadius: "10px",
                                          transition: "transform 0.3s ease",
                                          width: "100%",
                                        }}
                                      />
                                    ) : data?.includes("youtu") ? (
                                      <iframe
                                        id={`post_image_${post?.postId}`}
                                        onLoad={(e) =>
                                          post?.combinedURLs?.length === 1
                                            ? handleIframeLoad(e, post?.postId)
                                            : {}
                                        }
                                        title="youtube"
                                        loading="lazy"
                                        src={`https://www.youtube.com/embed/${getVideoId(
                                          data
                                        )}`}
                                        style={{ backgroundColor: "black" }}
                                        srcDoc={`<style>
          * {
          padding: 0;
          margin: 0;
          overflow: hidden;
          }
          
          body, html {
            height: 100%;
          }
          
          img, svg {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          
          svg {
            filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
            transition: all 250ms ease-in-out;
          }
          
          body:hover svg {
            filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
            transform: scale(1.2);
          }
        </style>
        <a href='https://www.youtube.com/embed/${getVideoId(data)}?autoplay=1'>
          <img src='https://img.youtube.com/vi/${getVideoId(
            data
          )}/hqdefault.jpg' alt='Coffee Recipe Javascript Project'><div class='play-button'>
          <button class="ytp-large-play-button ytp-button ytp-large-play-button-red-bg" aria-label="Play" title="Play"><svg version="1.1"  width='50' height='50' viewBox='0 0 60 50'><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button>
        </div> </a>
        `}
                                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        className={
                                          post?.combinedURLs?.length === 1
                                            ? "singlePost"
                                            : "multiplePost"
                                        }
                                      />
                                    ) : (
                                      <LinkPreview
                                        url={data}
                                        from={"POST"}
                                        noOfLinks={post?.youtubeURL?.length}
                                      />
                                    )}

                                    {post?.combinedURLs?.length >
                                      (isMobile ? 2 : 3) &&
                                      index === (isMobile ? 1 : 2) && (
                                        <div
                                          className="display-img-video"
                                          onClick={() => displayImgVideo(post)}
                                        >
                                          <span
                                            style={{
                                              fontSize: "50px",
                                              color: "#FFF",
                                            }}
                                          >
                                            +
                                            {post?.combinedURLs?.length -
                                              (isMobile ? 2 : 3)}
                                          </span>
                                        </div>
                                      )}
                                  </div>
                                )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="commentinfo ml-1 flex justify-between">
                    <div className="flex  gap-1">
                      {LikeAndDisLikeComponent(post, false, index)}
                      <div
                        className="post-comments"
                        onClick={(event) => {
                          isMobile
                            ? displayImgVideo(post)
                            : toggleComments(post);
                          event.stopPropagation();
                        }}
                      >
                        <span className="mr-2 flex flex-row items-center">
                          <img
                            src={
                              post?.member
                                ? !post?.communityBlocked &&
                                  !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                  ? CommentIcon
                                  : commentDisabled
                                : commentDisabled
                            }
                            className={"commenticon"}
                            alt=""
                          />{" "}
                          <span
                            className="commentCount ml-3"
                            style={{
                              color: post?.member
                                ? !post?.communityBlocked &&
                                  !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                  ? "black"
                                  : "gray"
                                : "gray",
                            }}
                          >
                            {post?.noOfComments + post?.noOfReplies > 0
                              ? post?.noOfComments + post?.noOfReplies
                              : null}
                          </span>{" "}
                        </span>
                      </div>
                      {post.type !== "POLL" && (
                        <div
                          className="mr-2 flex items-center"
                          onClick={(event) => {
                            if (post?.member) {
                              if (
                                !post?.communityBlocked &&
                                !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                              )
                                displaySharePost(post, "post");
                            }
                            event?.stopPropagation();
                          }}
                        >
                          <ShareAltOutlined
                            className={
                              post?.member
                                ? !post.communityBlocked && !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                  ? "like-share-icon "
                                  : "like-share-icon disabled"
                                : "like-share-icon disabled"
                            }
                            style={{
                              color: post?.member
                                ? !post.communityBlocked && !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                  ? "black"
                                  : "gray"
                                : "gray",
                            }}
                          />
                        </div>
                      )}
                      {communityMemberStatus === "admincommunity" &&
                        post?.reportOutputList?.length > 0 && (
                          <div
                            className="flex items-center"
                            onClick={(event) => {
                              if (
                                !post?.communityBlocked &&
                                !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                              ) {
                                setShowReportList(true);
                                setShowReportPost(post);
                              }
                            }}
                          >
                            <WarningOutlined
                              className="like-share-icon disabled"
                              style={{
                                color:
                                  !post?.communityBlocked &&
                                  !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                    ? "black"
                                    : "gray",
                              }}
                            />
                            <span
                              className="commentCount ml-1"
                              style={{
                                color:
                                  !post?.communityBlocked &&
                                  !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                                    ? "black"
                                    : "gray",
                              }}
                            >
                              {post?.reportOutputList?.length > 0
                                ? post?.reportOutputList?.length
                                : null}
                            </span>{" "}
                            {/* <i className="bi bi-send text-xl font-bold ml-2"></i> */}
                          </div>
                        )}
                    </div>

                    {isMobileView ? null : (
                      <TIMEAGO timestamp={post?.createdAt} />
                    )}
                  </div>
                  {commentPostId === post?.postId &&
                    (postCommentsData?.member ? (
                      <>
                        {!post?.communityBlocked && !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                          <div className="msg-comment-input">
                            {localStorage?.getItem("profileUrl") !== "null" ? (
                              <div
                                className={`capitalize relative inline-flex items-center 
                                                                    justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                onClick={() =>
                                  navigate(`/profile/${post?.userId}`)
                                }
                              >
                                <span className="font-medium ">
                                  <img
                                    src={
                                      localStorage?.getItem("profileUrl") || ""
                                    }
                                    alt=""
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor:
                                    localStorage?.getItem("profileColorCode") ||
                                    "",
                                }}
                                onClick={() =>
                                  navigate(`/profile/${post?.userId}`)
                                }
                                className={`capitalize relative inline-flex items-center 
                                                                    justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                              >
                                <span className="font-medium text-white">
                                  {localStorage
                                    ?.getItem("name")
                                    ?.substring(0, 1)}
                                </span>
                              </div>
                            )}
                            <Input
                              value={particularPostComment}
                              onChange={(e) =>
                                setParticularPostComment(e.target.value)
                              }
                              placeholder="Type your comments..."
                              onPressEnter={() => sendPostComments()}
                              maxLength={1000}
                              disabled={isSending} // Disable input when sending
                            />
                            {isSending ? (
                              <Spin
                                className="msg-send-icon"
                                style={{ borderRadius: "50%" }}
                              />
                            ) : (
                              <Button
                                onClick={() => sendPostComments()}
                                className="msg-send-icon"
                                shape="circle"
                                icon={<SendOutlined />}
                                disabled={isSending} // Disable input when sending
                              />
                            )}
                          </div>
                        ) : (
                          <div className="msg-chat-input">
                            {localStorage?.getItem("profileUrl") !== "null" ? (
                              <div
                                className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                onClick={() =>
                                  navigate(`/profile/${particularPost?.userId}`)
                                }
                              >
                                <span className="font-medium ">
                                  <img
                                    src={
                                      localStorage?.getItem("profileUrl") || ""
                                    }
                                    alt=""
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                  />
                                </span>
                              </div>
                            ) : (
                              <div
                                style={{
                                  backgroundColor:
                                    localStorage?.getItem("profileColorCode") ||
                                    "",
                                }}
                                onClick={() =>
                                  navigate(`/profile/${particularPost?.userId}`)
                                }
                                className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                              >
                                <span className="font-medium text-white">
                                  {localStorage
                                    ?.getItem("name")
                                    ?.substring(0, 1)}
                                </span>
                              </div>
                            )}
                            <Input
                              placeholder="Comments have been disabled"
                              disabled={true}
                            />
                            <Button
                              className="msg-send-icon"
                              shape="circle"
                              disabled={true}
                              icon={<SendOutlined />}
                            />
                          </div>
                        )}
                        {particularPostComment?.length > 0 && (
                          <p className="postCommentRemainingCharacters">
                            {particularPostComment?.length} / 1000
                          </p>
                        )}

                        {postCommentsMap[post?.postId]?.map(
                          (item: any, index: any) => (
                            <div key={index}>
                              <div className="flex m-3 items-center">
                                {item?.userProfileUrl ? (
                                  <div className="capitalize relative py-1 mr-4 rounded-full flex items-center justify-center">
                                    <img
                                      src={item?.userProfileUrl}
                                      style={{
                                        width: "45px",
                                        height: "45px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                      alt="commentProfile"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="capitalize relative text-[#FFFFFF] w-10 h-10 p-1 mr-4 rounded-full flex items-center justify-center"
                                    style={{
                                      backgroundColor: item?.profileColorCode,
                                    }}
                                  >
                                    {item?.author?.substring(0, 1)}
                                  </div>
                                )}
                                <div className="comment-content-container">
                                  <div className="comment-content">
                                    <p>
                                      <strong>{item?.author}</strong>
                                    </p>
                                    <span className="text-base">
                                      {item?.content}
                                    </span>
                                    <div className="flex justify-end">
                                      <TIMEAGO timestamp={item?.createdAt} />
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center justify-end">
                                    <div
                                      className="flex justify-end reply-button"
                                      onClick={() =>
                                        replyToComment(item?.commentId)
                                      }
                                    >
                                      Reply
                                    </div>
                                    {item?.noOfReplies > 0 && (
                                      <div
                                        className="flex justify-end noOfReplies ml-1"
                                        onClick={() =>
                                          handleViewReplies(item?.commentId)
                                        }
                                      >
                                        <i className="bi bi-dot"></i>{" "}
                                        {item?.noOfReplies}{" "}
                                        {item?.noOfReplies > 1
                                          ? "Replies"
                                          : "Reply"}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {replyingToCommentId === item?.commentId && (
                                <>
                                  {!post?.communityBlocked &&
                                  !post?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                                    <div className="reply-chat-input">
                                      {localStorage?.getItem("profileUrl") !==
                                      "null" ? (
                                        <div
                                          className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                          onClick={() =>
                                            navigate(`/profile/${post?.userId}`)
                                          }
                                        >
                                          <span className="font-medium ">
                                            <img
                                              src={
                                                localStorage?.getItem(
                                                  "profileUrl"
                                                ) || ""
                                              }
                                              alt=""
                                              style={{
                                                width: "45px",
                                                height: "45px",
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor:
                                              localStorage?.getItem(
                                                "profileColorCode"
                                              ) || "",
                                          }}
                                          onClick={() =>
                                            navigate(`/profile/${post?.userId}`)
                                          }
                                          className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                        >
                                          <span className="font-medium text-white">
                                            {localStorage
                                              ?.getItem("name")
                                              ?.substring(0, 1)}
                                          </span>
                                        </div>
                                      )}
                                      <Input
                                        value={particularPostReply}
                                        onChange={(e) =>
                                          setParticularPostReply(e.target.value)
                                        }
                                        placeholder="Type your reply..."
                                        onPressEnter={() =>
                                          sendReply(
                                            item?.commentId,
                                            particularPostReply,
                                            post?.postId
                                          )
                                        }
                                        maxLength={1000}
                                        disabled={isReplySending} // Disable input when sending
                                      />
                                      {isReplySending ? (
                                        <Spin
                                          className="msg-send-icon"
                                          style={{ borderRadius: "50%" }}
                                        />
                                      ) : (
                                        <Button
                                          onClick={() =>
                                            sendReply(
                                              item?.commentId,
                                              particularPostReply,
                                              post?.postId
                                            )
                                          }
                                          className="msg-send-icon"
                                          shape="circle"
                                          icon={<SendOutlined />}
                                          disabled={isReplySending}
                                        />
                                      )}
                                    </div>
                                  ) : (
                                    <div className="reply-chat-input">
                                      {localStorage?.getItem("profileUrl") !==
                                      "null" ? (
                                        <div
                                          className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                          onClick={() =>
                                            navigate(
                                              `/profile/${particularPost?.userId}`
                                            )
                                          }
                                        >
                                          <span className="font-medium ">
                                            <img
                                              src={
                                                localStorage?.getItem(
                                                  "profileUrl"
                                                ) || ""
                                              }
                                              alt=""
                                              style={{
                                                width: "45px",
                                                height: "45px",
                                                borderRadius: "50%",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </span>
                                        </div>
                                      ) : (
                                        <div
                                          style={{
                                            backgroundColor:
                                              localStorage?.getItem(
                                                "profileColorCode"
                                              ) || "",
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/profile/${particularPost?.userId}`
                                            )
                                          }
                                          className={`capitalize relative inline-flex items-center 
                    justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                        >
                                          <span className="font-medium text-white">
                                            {localStorage
                                              ?.getItem("name")
                                              ?.substring(0, 1)}
                                          </span>
                                        </div>
                                      )}
                                      <Input
                                        placeholder="Replies have been disabled"
                                        disabled={true}
                                      />
                                      <Button
                                        className="msg-send-icon"
                                        shape="circle"
                                        disabled={true}
                                        icon={<SendOutlined />}
                                      />
                                    </div>
                                  )}
                                  {particularPostReply?.length > 0 && (
                                    <p className="commentRemainingCharacters">
                                      {particularPostReply?.length} / 1000
                                    </p>
                                  )}
                                </>
                              )}
                              {commentRepliesMap[item?.commentId]?.map(
                                (reply, index) => (
                                  <div
                                    className="flex m-3 items-center replyComment"
                                    key={index}
                                  >
                                    {reply?.userProfileUrl ? (
                                      <div className="capitalize relative py-1 mr-4 rounded-full flex items-center justify-center">
                                        <img
                                          src={reply?.userProfileUrl}
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                          }}
                                          alt="commentProfile"
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="capitalize relative text-[#FFFFFF] w-10 h-10 p-1 mr-4 rounded-full flex items-center justify-center"
                                        style={{
                                          backgroundColor:
                                            reply?.profileColorCode,
                                        }}
                                      >
                                        {reply?.author?.substring(0, 1)}
                                      </div>
                                    )}
                                    <div className="reply-content-container">
                                      <div className="reply-content">
                                        {reply?.author?.length > 0 && (
                                          <p>
                                            <strong>{reply?.author}</strong>
                                          </p>
                                        )}
                                        <h3>{reply?.content}</h3>
                                        <div className="flex justify-end">
                                          <TIMEAGO
                                            timestamp={reply?.createdAt}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          )
                        )}
                        {postCommentsMap[post?.postId]?.length > 0 &&
                          postCommentsMap[post?.postId]?.length !==
                            post?.noOfComments && (
                            <button
                              onClick={loadMoreComments}
                              className="load-more-button"
                            >
                              Load More Comments...
                            </button>
                          )}
                      </>
                    ) : (
                      <>
                        <div className="msg-chat-input">
                          {localStorage?.getItem("profileUrl") !== "null" ? (
                            <div
                              className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                              onClick={() =>
                                navigate(`/profile/${particularPost?.userId}`)
                              }
                            >
                              <span className="font-medium ">
                                <img
                                  src={
                                    localStorage?.getItem("profileUrl") || ""
                                  }
                                  alt=""
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor:
                                  localStorage?.getItem("profileColorCode") ||
                                  "",
                              }}
                              onClick={() =>
                                navigate(`/profile/${particularPost?.userId}`)
                              }
                              className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                            >
                              <span className="font-medium text-white">
                                {localStorage?.getItem("name")?.substring(0, 1)}
                              </span>
                            </div>
                          )}
                          <Input
                            placeholder="Join the community to comment"
                            disabled={true}
                          />
                          <Button
                            className="msg-send-icon"
                            shape="circle"
                            disabled={true}
                            icon={<SendOutlined />}
                          />
                        </div>
                        <div className="comment-section">
                          {postCommentsMap[post?.postId]?.map(
                            (item: any, index: any) => (
                              <div
                                className="flex m-3 items-center"
                                key={index}
                              >
                                {item?.userProfileUrl ? (
                                  <div
                                    className="capitalize relative py-1 mr-4
                                                                  rounded-full flex items-center justify-center"
                                  >
                                    <img
                                      src={item?.userProfileUrl}
                                      style={{
                                        width: "45px",
                                        height: "45px",
                                        borderRadius: "50%",
                                        objectFit: "cover",
                                      }}
                                      alt="commentProfile"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                                                                  rounded-full flex items-center justify-center"
                                    style={{
                                      backgroundColor: item?.profileColorCode,
                                    }}
                                  >
                                    {item?.author?.substring(0, 1)}
                                  </div>
                                )}

                                <div className="comment-content-container">
                                  <div className="comment-content">
                                    <p>
                                      <strong>{item?.author}</strong>
                                    </p>
                                    <h3>{item?.content}</h3>
                                    <div className="flex justify-end">
                                      <TIMEAGO timestamp={item?.createdAt} />
                                    </div>
                                  </div>
                                  <div className="flex flex-row items-center justify-end">
                                    <div
                                      className="flex justify-end reply-button"
                                      onClick={() => {
                                        replyToComment(item?.commentId);
                                      }}
                                    >
                                      Reply
                                    </div>
                                    {item?.noOfReplies > 0 && (
                                      <div
                                        className="flex justify-end noOfReplies ml-1"
                                        onClick={() => {
                                          replyToComment(item?.commentId);
                                        }}
                                      >
                                        <i className="bi bi-dot"></i>{" "}
                                        {item?.noOfReplies}{" "}
                                        {item?.noOfReplies > 1
                                          ? "Replies"
                                          : "Reply"}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </>
                    ))}
                </div>
              ))}
          </div>
          <div className="feeds-modal">
            <div>
              <Modal
                open={shareModal}
                footer={false}
                transitionName="ant-modal-slide-up"
                width={"100%"}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  height: "86.5vh",
                  overflowY: "hidden",
                  padding: "0px",
                  width: "100%",
                }}
                // centered
                onCancel={() => setShareModal(false)}
                title={
                  <div className="flex flex-row items-center justify-center pt-3">
                    Share Post
                  </div>
                }
              >
                <div className="flex flex-row justify-center py-5 px-10">
                  <div className="flex flex-col items-center">
                    <UsergroupAddOutlined
                      className="text-3xl"
                      onClick={() => {
                        setShareModal(false);
                        setCreatePopup(true);
                      }}
                    />
                    <span
                      onClick={() => {
                        setShareModal(false);
                        setCreatePopup(true);
                      }}
                    >
                      Via Communities
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                open={toggleImgVideoPost}
                title={
                  <div>
                    <div className="feeds-header">
                      {particularPost?.userProfileUrl ? (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={particularPost?.userProfileUrl}
                            alt=""
                            style={{
                              width: "50px",
                              height: "50px",
                              borderRadius: "50%",
                              objectFit: "cover",
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className=""
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "40px",
                            height: "40px",
                            color: "#FFF",
                            borderRadius: "50%",
                            backgroundColor: particularPost?.profileColorCode,
                          }}
                        >
                          {particularPost?.userName?.substring(0, 1)}
                        </div>
                      )}

                      <div className="p-0 pl-2" style={{ width: "90%" }}>
                        <p>
                          <span>{particularPost?.userName}</span>
                        </p>
                        <div className="text-sm">
                          <TIMEAGO timestamp={particularPost?.createdAt} />
                        </div>
                      </div>
                    </div>
                    <div
                      className="downArrow showMobileButton"
                      onClick={handleCancel}
                    >
                      <img src={downArrow} alt="" />
                    </div>
                  </div>
                }
                width={"40%"}
                className="modalpopup showMobile communityPreviewpopupModal"
                bodyStyle={{
                  overflowY: "scroll",
                  height: `${
                    particularPost?.combinedURLs?.length ||
                    particularPost.noOfComments >= 3 ||
                    particularPost?.content?.length > 1000
                      ? "auto"
                      : "250px"
                  }`,
                  maxHeight: "calc(90vh - 150px)",
                }}
                onCancel={handleCancel}
                footer={[
                  particularPost?.member ? (
                    <>
                      {!particularPost?.communityBlocked &&
                      !particularPost?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                        <div className="msg-chat-input">
                          {localStorage?.getItem("profileUrl") !== "null" ? (
                            <div
                              className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                            >
                              <span className="font-medium ">
                                <img
                                  src={
                                    localStorage?.getItem("profileUrl") || ""
                                  }
                                  alt=""
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor:
                                  localStorage?.getItem("profileColorCode") ||
                                  "",
                              }}
                              className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                            >
                              <span className="font-medium text-white">
                                {localStorage?.getItem("name")?.substring(0, 1)}
                              </span>
                            </div>
                          )}
                          <Input
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Type your comments..."
                            onPressEnter={() => {
                              sendComments();
                            }}
                            maxLength={1000}
                            disabled={isSending} // Disable input when sending
                          />
                          {isSending ? (
                            <Spin
                              className="msg-send-icon"
                              style={{ borderRadius: "50%" }}
                            />
                          ) : (
                            <Button
                              onClick={() => {
                                sendComments();
                              }}
                              className="msg-send-icon"
                              shape="circle"
                              icon={<SendOutlined />}
                              disabled={isSending} // Disable input when sending
                            />
                          )}
                        </div>
                      ) : (
                        <div className="msg-chat-input">
                          {localStorage?.getItem("profileUrl") !== "null" ? (
                            <div
                              className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                            >
                              <span className="font-medium ">
                                <img
                                  src={
                                    localStorage?.getItem("profileUrl") || ""
                                  }
                                  alt=""
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              </span>
                            </div>
                          ) : (
                            <div
                              style={{
                                backgroundColor:
                                  localStorage?.getItem("profileColorCode") ||
                                  "",
                              }}
                              className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                            >
                              <span className="font-medium text-white">
                                {localStorage?.getItem("name")?.substring(0, 1)}
                              </span>
                            </div>
                          )}
                          <Input
                            placeholder="Comments have been disabled"
                            disabled={true}
                          />
                          <Button
                            className="msg-send-icon"
                            shape="circle"
                            disabled={true}
                            icon={<SendOutlined />}
                          />
                        </div>
                      )}
                      {comment?.length > 0 && (
                        <p className="modalCommentRemainingCharacters">
                          {comment?.length} / 1000
                        </p>
                      )}
                    </>
                  ) : (
                    <div className="msg-chat-input">
                      {localStorage?.getItem("profileUrl") !== "null" ? (
                        <div
                          className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                          onClick={() =>
                            navigate(`/profile/${particularPost?.userId}`)
                          }
                        >
                          <span className="font-medium ">
                            <img
                              src={localStorage?.getItem("profileUrl") || ""}
                              alt=""
                              style={{
                                width: "45px",
                                height: "45px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            />
                          </span>
                        </div>
                      ) : (
                        <div
                          style={{
                            backgroundColor:
                              localStorage?.getItem("profileColorCode") || "",
                          }}
                          onClick={() =>
                            navigate(`/profile/${particularPost?.userId}`)
                          }
                          className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                        >
                          <span className="font-medium text-white">
                            {localStorage?.getItem("name")?.substring(0, 1)}
                          </span>
                        </div>
                      )}
                      <Input
                        placeholder="Join the community to comment"
                        disabled={true}
                      />
                      <Button
                        className="msg-send-icon"
                        shape="circle"
                        disabled={true}
                        icon={<SendOutlined />}
                      />
                    </div>
                  ),
                ]}
              >
                <div
                  className={`${
                    particularPost?.combinedURLs?.length === 0 &&
                    particularPost.noOfComments === 0
                      ? "FeedPostContent flex flex-col"
                      : "feedsPostContentFontSize flex flex-col"
                  } `}
                >
                  <div className="flex flex-row tagContainer flex-wrap">
                    {particularPost?.tags
                      ?.filter(
                        (postTag: any) => postTag?.toLowerCase() !== "general"
                      )
                      ?.map((tag: any) => (
                        <div className="flex flex-row tag">{tag}</div>
                      ))}
                  </div>
                  {postlink(particularPost?.content)}
                </div>
                {popupImgVideo && (
                  <>
                    <div className="feeds-img-video-container1">
                      <div className="postsImage">
                        <>
                          {imgPrevNext === 0 ? (
                            ""
                          ) : (
                            <div
                              className="prev-icon"
                              onClick={() =>
                                setImgPrevNext((prevState: any) =>
                                  prevState - 1 <= 0 ? 0 : prevState - 1
                                )
                              }
                            >
                              <LeftCircleOutlined />
                            </div>
                          )}
                          <div
                            style={{
                              position: "relative",
                              width: "85%",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {popupImgVideo?.type === "video" ? (
                              <video className="video" controls>
                                <source
                                  src={popupImgVideo?.url}
                                  type="video/mp4"
                                />
                              </video>
                            ) : imageType.some((fileType) =>
                                popupImgVideo?.url?.includes(fileType)
                              ) ? (
                              <img
                                className="image"
                                src={popupImgVideo?.url}
                                alt=""
                                style={{
                                  borderRadius: "10px",
                                  transition: "transform 0.3s ease",
                                }}
                              />
                            ) : popupImgVideo?.url?.includes("youtu") ? (
                              <iframe
                                title="youtube"
                                loading="lazy"
                                src={`https://www.youtube.com/embed/${getVideoId(
                                  popupImgVideo?.url
                                )}`}
                                style={{ backgroundColor: "black" }}
                                width="100%"
                                srcDoc={`<style>
                              * {
                              padding: 0;
                              margin: 0;
                              overflow: hidden;
                              }
                              
                              body, html {
                                height: 100%;
                              }
                              
                              img, svg {
                                position: absolute;
                                width: 100%;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                              }
                              
                              svg {
                                filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
                                transition: all 250ms ease-in-out;
                              }
                              
                              body:hover svg {
                                filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
                                transform: scale(1.2);
                              }
                            </style>
                            <a href='https://www.youtube.com/embed/${getVideoId(
                              popupImgVideo?.url
                            )}?autoplay=1'>
                              <img src='https://img.youtube.com/vi/${getVideoId(
                                popupImgVideo?.url
                              )}/hqdefault.jpg' alt='Coffee Recipe Javascript Project'><div class='play-button' >
                              <button style={{cursor: "pointer"}} class="ytp-large-play-button ytp-button ytp-large-play-button-red-bg" aria-label="Play" title="Play"><svg version="1.1" width='50' height='50' viewBox='0 0 60 50'><path class="ytp-large-play-button-bg" d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00"></path><path d="M 45,24 27,14 27,34" fill="#fff"></path></svg></button>
                            </div> </a>
                            `}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                // style={{height:"350px",width:"600px"}}
                                className="singlePost"
                              />
                            ) : (
                              <LinkPreview
                                url={popupImgVideo?.url}
                                from={"MODAL"}
                              />
                            )}
                          </div>

                          {particularPost?.combinedURLs?.length - 1 ===
                          imgPrevNext ? (
                            ""
                          ) : (
                            <div
                              className="next-icon"
                              onClick={() =>
                                setImgPrevNext((prevState: any) =>
                                  prevState + 1 >
                                  particularPost?.combinedURLs?.length - 1
                                    ? prevState
                                    : prevState + 1
                                )
                              }
                            >
                              <RightCircleOutlined />
                            </div>
                          )}
                        </>
                      </div>
                    </div>
                  </>
                )}
                <div className="post-like-share">
                  <div className="flex">
                    {LikeAndDisLikeComponent(particularPost, true)}
                  </div>
                  <div
                    className="flex items-center cursor-pointer !m-0"
                    onClick={(event) => {
                      if (particularPost?.member) {
                        if (
                          !particularPost?.communityBlocked &&
                          !particularPost?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                        )
                          displaySharePost(particularPost, "modal");
                      }
                      // handleShare()
                      event.stopPropagation();
                    }}
                  >
                    <ShareAltOutlined
                      className={
                        particularPost?.member
                          ? !particularPost?.communityBlocked &&
                            !particularPost?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                            ? "like-share-icon"
                            : "like-share-icon disabled"
                          : "like-share-icon disabled"
                      }
                      style={{
                        color: particularPost?.member
                          ? !particularPost?.communityBlocked &&
                            !particularPost?.memberBlocked && !(loginVia === "email" && !isEmailVerified)
                            ? "black"
                            : "gray"
                          : "gray",
                      }}
                    />
                    <span>Share</span>
                  </div>
                </div>

                <div className="comment-section">
                  {postComments[particularPost?.postId]?.map(
                    (item: any, index: any) => (
                      <>
                        <div className="flex m-3 items-center" key={index}>
                          {item?.userProfileUrl ? (
                            <div
                              className="capitalize relative py-1 mr-4
                                rounded-full flex items-center justify-center"
                            >
                              <img
                                src={item?.userProfileUrl}
                                style={{
                                  width: "45px",
                                  height: "45px",
                                  borderRadius: "50%",
                                  objectFit: "cover",
                                }}
                                alt=""
                              />
                            </div>
                          ) : (
                            <div
                              className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                                rounded-full flex items-center justify-center"
                              style={{
                                backgroundColor: item?.profileColorCode,
                              }}
                            >
                              {item?.author?.substring(0, 1)}
                            </div>
                          )}

                          <div className="comment-content-container">
                            <div className="comment-content">
                              <p>
                                <strong>{item?.author}</strong>
                              </p>
                              <h3>{item?.content}</h3>
                              <div className="flex justify-end">
                                <TIMEAGO timestamp={item?.createdAt} />
                              </div>
                            </div>
                            <div className="flex flex-row items-center justify-end">
                              <div
                                className="flex justify-end reply-button"
                                onClick={() => {
                                  replyToComment(item?.commentId);
                                }}
                              >
                                Reply
                              </div>
                              {item?.noOfReplies > 0 && (
                                <div
                                  className="flex justify-end noOfReplies ml-1"
                                  onClick={() => {
                                    handleModalViewReplies(item?.commentId);
                                  }}
                                >
                                  <i className="bi bi-dot"></i>{" "}
                                  {item?.noOfReplies}{" "}
                                  {item?.noOfReplies > 1 ? "Replies" : "Reply"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {replyingToCommentId === item?.commentId && (
                          <>
                            {particularPost?.member ? (
                              !particularPost?.communityBlocked &&
                              !particularPost?.memberBlocked && !(loginVia === "email" && !isEmailVerified) ? (
                                <div className="reply-chat-input">
                                  {localStorage?.getItem("profileUrl") !==
                                  "null" ? (
                                    <div
                                      className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                      onClick={() =>
                                        navigate(
                                          `/profile/${particularPost?.userId}`
                                        )
                                      }
                                    >
                                      <span className="font-medium ">
                                        <img
                                          src={
                                            localStorage?.getItem(
                                              "profileUrl"
                                            ) || ""
                                          }
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor:
                                          localStorage?.getItem(
                                            "profileColorCode"
                                          ) || "",
                                      }}
                                      onClick={() =>
                                        navigate(
                                          `/profile/${particularPost?.userId}`
                                        )
                                      }
                                      className={`capitalize relative inline-flex items-center 
                            justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                    >
                                      <span className="font-medium text-white">
                                        {localStorage
                                          ?.getItem("name")
                                          ?.substring(0, 1)}
                                      </span>
                                    </div>
                                  )}
                                  <Input
                                    value={particularPostReply}
                                    onChange={(e) =>
                                      setParticularPostReply(e.target.value)
                                    }
                                    placeholder="Type your reply..."
                                    onPressEnter={() =>
                                      sendReply(
                                        item?.commentId,
                                        particularPostReply,
                                        particularPost?.postId
                                      )
                                    }
                                    maxLength={1000}
                                    disabled={isReplySending} // Disable input when sending
                                  />
                                  {isReplySending ? (
                                    <Spin
                                      className="msg-send-icon"
                                      style={{ borderRadius: "50%" }}
                                    />
                                  ) : (
                                    <Button
                                      onClick={() =>
                                        sendReply(
                                          item?.commentId,
                                          particularPostReply,
                                          particularPost?.postId
                                        )
                                      }
                                      className="msg-send-icon"
                                      shape="circle"
                                      icon={<SendOutlined />}
                                      disabled={isReplySending}
                                    />
                                  )}
                                </div>
                              ) : (
                                <div className="reply-chat-input">
                                  {localStorage?.getItem("profileUrl") !==
                                  "null" ? (
                                    <div
                                      className={`capitalize relative inline-flex items-center 
                                                                        justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                    >
                                      <span className="font-medium ">
                                        <img
                                          src={
                                            localStorage?.getItem(
                                              "profileUrl"
                                            ) || ""
                                          }
                                          alt=""
                                          style={{
                                            width: "45px",
                                            height: "45px",
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </span>
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor:
                                          localStorage?.getItem(
                                            "profileColorCode"
                                          ) || "",
                                      }}
                                      className={`capitalize relative inline-flex items-center 
                                                                        justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                    >
                                      <span className="font-medium text-white">
                                        {localStorage
                                          ?.getItem("name")
                                          ?.substring(0, 1)}
                                      </span>
                                    </div>
                                  )}
                                  <Input
                                    placeholder="Replies have been disabled"
                                    disabled={true}
                                  />
                                  <Button
                                    className="msg-send-icon"
                                    shape="circle"
                                    disabled={true}
                                    icon={<SendOutlined />}
                                  />
                                </div>
                              )
                            ) : (
                              <div className="reply-chat-input">
                                {localStorage?.getItem("profileUrl") !==
                                "null" ? (
                                  <div
                                    className={`capitalize relative inline-flex items-center 
                  justify-center w-10 h-10 overflow-hidden  rounded-full`}
                                    onClick={() =>
                                      navigate(
                                        `/profile/${particularPost?.userId}`
                                      )
                                    }
                                  >
                                    <span className="font-medium ">
                                      <img
                                        src={
                                          localStorage?.getItem("profileUrl") ||
                                          ""
                                        }
                                        alt=""
                                        style={{
                                          width: "45px",
                                          height: "45px",
                                          borderRadius: "50%",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor:
                                        localStorage?.getItem(
                                          "profileColorCode"
                                        ) || "",
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/profile/${particularPost?.userId}`
                                      )
                                    }
                                    className={`capitalize relative inline-flex items-center 
                  justify-center w-10 h-10 overflow-hidden  rounded-full dark:[#804C31]`}
                                  >
                                    <span className="font-medium text-[#FFFFFF]">
                                      {localStorage
                                        ?.getItem("name")
                                        ?.substring(0, 1)}
                                    </span>
                                  </div>
                                )}
                                <Input
                                  placeholder="Join the community to reply"
                                  disabled={true}
                                />
                                <Button
                                  className="msg-send-icon"
                                  shape="circle"
                                  disabled={true}
                                  icon={<SendOutlined />}
                                />
                              </div>
                            )}
                          </>
                        )}
                        {commentRepliesMap[item?.commentId]?.map(
                          (reply, index) => (
                            <div
                              className="flex m-3 items-center replyComment"
                              key={index}
                            >
                              {reply?.userProfileUrl ? (
                                <div
                                  className="capitalize relative py-1 mr-4
                          rounded-full flex items-center justify-center"
                                >
                                  <img
                                    src={reply?.userProfileUrl}
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                    }}
                                    alt="commentProfile"
                                  />
                                </div>
                              ) : (
                                <div
                                  className="capitalize relative  text-[#FFFFFF] w-10 h-10 p-1 mr-4
                          rounded-full flex items-center justify-center"
                                  style={{
                                    backgroundColor: reply?.profileColorCode,
                                  }}
                                >
                                  {reply?.author?.substring(0, 1)}
                                </div>
                              )}
                              <div className="reply-content-container">
                                <div className="reply-content">
                                  {reply?.author?.length > 0 && (
                                    <p>
                                      <strong>{reply?.author}</strong>
                                    </p>
                                  )}
                                  <h3>{reply?.content}</h3>
                                  <div className="flex justify-end">
                                    <TIMEAGO timestamp={reply?.createdAt} />
                                  </div>
                                </div>
                              </div>

                              {/* </div> */}
                              {/* </span> */}
                            </div>
                            // ...
                          )
                        )}
                      </>
                    )
                  )}
                </div>
                {postComments[particularPost?.postId]?.length > 0 &&
                  postComments[particularPost?.postId]?.length !==
                    particularPost?.noOfComments && (
                    <button
                      onClick={loadMoreModalComments}
                      className="load-more-button"
                    >
                      Load More Comments...
                    </button>
                  )}
              </Modal>
            </div>
            {createPopup && (
              <FeedsComponentPopup
                onCancelClick={() => {
                  setCreatePopup(false);
                }}
                onSaveClick={() => {
                  setCreatePopup(false);
                  props?.refreshPosts();
                }}
                communityPostContent={sharePost?.content}
                metaData={sharePost?.metaData}
                from={"CommunityPosts"}
                tags={sharePost?.tags?.filter(
                  (postTag: any) => postTag?.toLowerCase() !== "general"
                )}
              />
            )}
          </div>
          <div
            className={toggleImgVideoPost ? "overlay show" : "overlay"}
          ></div>
        </div>
      ) : (
        <>
          <div className="headingFeedLabel">
            <div className="headtop">Posts</div>
            {props?.from !== "Profile" &&
              // (popupCommunityList?.some((community:any) => community.communityId === props?.id)) &&
              // props?.isMember &&
              !props?.communityBlocked &&
              !props?.memberBlocked && !(loginVia === "email" && !isEmailVerified) &&
              (props?.admin || // Admin can always see the button
                (props?.isMember && !props?.postsOnlyByAdmin)) && ( // Members can see the button if postsOnlyByAdmin is false
                <div>
                  <button
                    className="postcreateButton showDesktop"
                    onClick={() => {
                      setCreatePostPopup(true);
                    }}
                  >
                    <div className="plusButtonSize">+</div>
                  </button>
                </div>
              )}
          </div>
          <div className="flex flex-col items-center pt-10 pb-10">
            <img src={image} className="noPostsThumbnail" alt="" />
            <div className="text-gray-500 noPostsText"> No posts yet </div>
          </div>
        </>
      )}
      {showPoll && (
        <div className="pollPopup">
          <Modal
            centered
            open
            className="pollModal"
            title={
              <div
                className="modalTitle flex flex-row items-center"
                style={{
                  padding: "10px 0px 10px 10px",
                  backgroundImage: "linear-gradient(270deg, #804C31, #67702E)",
                  color: "white",
                  borderRadius: "8px 8px 0 0",
                  //  backgroundColor:"#804C31", borderRadius:"5px 5px 0 0", color:"white"
                }}
              >
                <i className="bi bi-bar-chart-fill"></i>
                <span className="px-2">Poll</span>
              </div>
            }
            onCancel={() => {
              const resetRemainingPollUsers: any = {};
              particularPostPoll?.pollOutput?.options?.forEach((poll: any) => {
                resetRemainingPollUsers[poll.option] = null;
              });
              setRemainingPollUsers(resetRemainingPollUsers);
              setShowPoll(false);
            }}
            footer={false}
          >
            <div className="pollModalContainer">
              <span className="text-[#06be51] md:text-lg text-sm font-medium mb-5">
                Total votes : {particularPostPoll?.pollOutput?.totalVotes}
              </span>
              {particularPostPoll?.pollOutput?.options?.map(
                (poll: any, index: any) => (
                  <>
                    <div
                      key={index}
                      className="flex flex-row items-center py-2 pl-5 pollModalOptions"
                      style={{
                        justifyContent: "space-between",
                        backgroundImage:
                          "linear-gradient(270deg, #e2e5e6, #e2e5e6)",
                        backgroundSize: `${
                          (poll?.votes /
                            particularPostPoll?.pollOutput?.totalVotes) *
                          100
                        }%`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "#f8f8f8",
                      }}
                    >
                      <div className="poll-radio-option">{poll?.option}</div>
                      <div className="text-[16px] pr-3 text-[#00000] font-normal">
                        <span>
                          {poll?.votes} {poll?.votes === 1 ? "vote" : "votes"}
                        </span>
                      </div>
                    </div>
                    <div className="two-columns-grid">
                      {poll?.users?.length > 0 ? (
                        (poll?.users?.length > 3
                          ? poll?.users?.slice(0, 3)
                          : poll?.users
                        )?.map((user: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center mx-5 pollUser"
                          >
                            {user?.profileUrl ? (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember`}
                                //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }

                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <img src={user?.profileUrl} />
                              </div>
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember ${
                                  index === 0 ? "" : "" // Apply green border to the first element
                                }`}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <span
                                  className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                  style={{
                                    color: "white",
                                    fontSize: "1.15rem",
                                  }}
                                >
                                  <span className="memberSubstring">
                                    {user?.member?.name?.substring(0, 1)}
                                  </span>
                                </span>
                              </div>
                            )}

                            <div
                              className="flex items-center flex-col modalmemberName"
                              style={{ color: "black" }}
                            >
                              <span className="postMembertitle capitalize ml-2">
                                <span className="modalmemberName">
                                  {user?.member?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                      {poll?.users?.length > 3 &&
                        remainingPollUsers[poll?.option] === null && (
                          <div
                            className="flex flex-row items-center mx-5 pollViewAll"
                            style={{ color: "#06be51" }}
                            onClick={() => {
                              handleViewAllUsers(poll?.option);
                            }}
                          >
                            View All
                          </div>
                        )}

                      {remainingPollUsers[poll?.option]?.map(
                        (user: any, index: any) => (
                          <div
                            key={index}
                            className="flex flex-row items-center mx-5 pollUser"
                          >
                            {user?.profileUrl ? (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember`}
                                //  ${ index === 0 ? "border-2 border-[#67702E]" : "" }
                                onClick={() => navigate(`/profile/${user?.id}`)}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <img src={user?.profileUrl} />
                              </div>
                            ) : (
                              <div
                                className={`capitalize relative inline-flex items-center justify-center overflow-hidden rounded-full modalmember ${
                                  index === 0 ? "" : "" // Apply green border to the first element
                                }`}
                                onClick={() => navigate(`/profile/${user?.id}`)}
                                style={{
                                  backgroundColor: user?.profileColorCode,
                                }}
                              >
                                <span
                                  className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                                  style={{
                                    color: "white",
                                    fontSize: "1.15rem",
                                  }}
                                  onClick={() =>
                                    navigate(`/profile/${user?.id}`)
                                  }
                                >
                                  <span className="memberSubstring">
                                    {user?.member?.name?.substring(0, 1)}
                                  </span>
                                </span>
                              </div>
                            )}

                            <div
                              className="flex items-center flex-col modalmemberName"
                              style={{ color: "black" }}
                              onClick={() => navigate(`/profile/${user?.id}`)}
                            >
                              <span className="postMembertitle capitalize ml-2">
                                <span className="modalmemberName">
                                  {user?.member?.name}
                                </span>
                              </span>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </>
                )
              )}
            </div>
          </Modal>
        </div>
      )}
      {showReportModal && (
        <>
          <ReportPostModal
            onCancel={() => {
              setShowReportModal(false);
              setPostId("");
            }}
            postId={postId}
            postData={posts}
            setPostsData={setPosts}
          />
        </>
      )}

      {showReportList && (
        <Modal
          centered
          open
          title={
            <div
              className="modalTitle"
              style={{ padding: "10px 0px 0px 10px" }}
            >
              Post Reports
            </div>
          }
          onCancel={() => {
            setShowReportList(false);
          }}
          footer={false}
        >
          <div className="modalContainer">
            {showReportPost?.reportOutputList?.map(
              (reportMember: any, index: any) => (
                <div
                  key={index}
                  className="flex flex-row items-center py-2 pl-5"
                >
                  {reportMember?.member?.profileUrl ? (
                    <div
                      className={`capitalize relative inline-flex items-center cursor-pointer  justify-center overflow-hidden rounded-full modalmember`}
                      style={{
                        backgroundColor: reportMember?.member?.profileColorCode,
                      }}
                    >
                      <img src={reportMember?.member?.profileUrl} />
                    </div>
                  ) : (
                    <div
                      className={`capitalize relative inline-flex items-center cursor-pointer justify-center overflow-hidden rounded-full modalmember ${
                        index === 0 ? "" : "" // Apply green border to the first element
                      }`}
                      style={{
                        backgroundColor: reportMember?.member?.profileColorCode,
                      }}
                    >
                      <span
                        className="text-white dark:text-gray-100 h-12 flex flex-col items-center cursor-pointer justify-center"
                        style={{
                          color: "white",
                          fontSize: "1.15rem",
                        }}
                      >
                        <span className="memberSubstring">
                          {reportMember?.member?.member?.name?.substring(0, 1)}
                        </span>
                      </span>
                    </div>
                  )}
                  <div className="flex flex-row items-center">
                    <div
                      className="flex items-center flex-col modalmemberName cursor-pointer"
                      style={{ color: "black" }}
                    >
                      <span className="postMembertitle capitalize ml-2">
                        <span className="modalmemberName">
                          {reportMember?.member?.member?.name}
                        </span>
                      </span>
                    </div>
                    :{" "}
                    <span className="flex flex-row items-center reasonContainer postMembertitle">
                      <WarningOutlined className="mx-1" />
                      {reportMember?.reason}
                    </span>
                  </div>
                </div>
              )
            )}
          </div>
        </Modal>
      )}
      {createPostPopup && (
        <FeedsComponentPopup
          onCancelClick={() => {
            setCreatePostPopup(false);
          }}
          onSaveClick={() => {
            setCreatePostPopup(false);
            props?.refreshPosts();
          }}
          defaultCommunity={props?.id}
        />
      )}
      {props?.from !== "Profile" &&
        // (popupCommunityList?.some((community:any) => community.communityId === props?.id)) &&
        // props?.isMember &&
        !props?.communityBlocked &&
        !props?.memberBlocked && !(loginVia === "email" && !isEmailVerified) &&
        (props?.admin || // Admin can always see the button
          (props?.isMember && !props?.postsOnlyByAdmin)) && ( // Members can see the button if postsOnlyByAdmin is false
          <div
            className="plusCreate showMobileIcon"
            // type="primary"
            onClick={() => {
              setCreatePostPopup(true);
            }}
            style={{ marginTop: "-1px" }}
          >
            <div className="" style={{ fontSize: "45px" }}>
              <img
                src={AddIcon}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  border: "0.5px solid gray",
                  borderRadius: "50%",
                }}
              />
            </div>
          </div>
        )}
    </div>
  );
}

export default CommunityPosts;

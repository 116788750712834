import React, { useEffect, useState, useRef, ChangeEvent } from "react";
import { request } from "../../Utils/request";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import dayjs from "dayjs";
import { multiFormRequest } from "../../Utils/request";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Radio,
  Switch,
  Modal,
  ConfigProvider,
  Checkbox,
  Dropdown,
  Menu
} from "antd";
import {
  DownOutlined,
  UpOutlined,
  DeleteOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import { Select } from "antd";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { getToken } from "../../Utils/timeago";
import Compressor from "compressorjs";
import { isMobile } from "../../Utils/mobileSettings";

type ProfileProps = {
  onClose: () => void;
  // parentCallback: Function;
};
interface FamilyMember {
  userId: string;
  relationship: string;
  name: string;
  emailId: string;
  searchQuery: string;
  searchResults: any;
}

// Define the type for the payload
interface PayloadType {
  email: string;
  role: string;
  firebaseUID: string | null;
  signUpVia: string;
  type: string;
  workingType: string;
  phoneNumber: string;
  currentLocation: string;
  showMatrimonyProfiles: boolean;
  showFamilyTree: boolean;
  interests: string[];
  member: {
    name: string;
    currentState: string;
    currentCity: string;
    originState: string;
    originCity: string;
    place: string;
    dob: string;
    gender: string;
    relationships?: Array<{ userId: string; relation: string }>;
    religion: string;
    caste: string;
    subCaste: string;
    familyName: string;
    workplace: string;
    occupation: string;
    university: string;
  };
  profileUrl?:any; // Make profileUrl optional
  bannerUrl?:any; // Make profileUrl optional
}

type InputRefs = {
  [key: number]: React.RefObject<HTMLInputElement>;
};
interface FormFieldNames {
  [key: string]: string;
}

const dateFormat = "YYYY/MM/DD";
const endDate = dayjs().subtract(18, "years");

const disabledDate = (current: dayjs.Dayjs | null) => {
  if (!current) {
    return false;
  }
  return current.isAfter(endDate, "day");
};
const currentDate = dayjs();
const date18YearsAgo = currentDate.subtract(18, "year");

const UpdateProfile = ({ onClose }: ProfileProps) => {

  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const hiddenBannerFileInput = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  useEffect(() => {
    // parentCallback();
  }, []);
  // const [bannerImage, setBannerImage] = useState("");
  const [profile, setProfile] = useState<any>({});
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [bannerImage, setBannerImage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const phoneNumber = localStorage?.getItem("phoneNumber")?.substr(1);
  const user = JSON.parse(localStorage?.getItem("user") || "");
  const email = user?.email || user?.providerData[0]?.email;
  const loginVia = localStorage.getItem("loginInVia");
  const [imageName, setImageName] = useState<string>("");
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const userExist = localStorage.getItem("userExist");
  const [showContactInfo, setShowContactInfo] = useState<boolean>(true);
  const [showIntro, setShowintro] = useState<boolean>(true);
  const [addWork, setAddWork] = useState<boolean>(false);
  const [addCity, setAddCity] = useState<boolean>(false);
  const [addUniversity, setAddUniversity] = useState<boolean>(false);
  const [addHobbies, setAddHobbies] = useState<boolean>(false);
  const [addReligion, setAddReligion] = useState<boolean>(false);
  const [addFamilyName, setAddFamilyName] = useState<boolean>(false);
  // const [addFamilyMember, setFamilyMember] = useState<boolean>(false);
  const [addRelationshipStatus, setRelationshipStatus] =
    useState<boolean>(false);
  const [profileScore, setProfileScore] = useState();
  const [exitModalVisible, setExitModalVisible] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [deleteClick, setDeleteClick] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [livesInData, setLocationData] = useState<any>([]);
  const [usernameError, setUsernameError] = useState<any>("");
  const [isCelebrityName, setIsCelebrityName] = useState(false);
  const [isProfileImageRemoved, setIsProfileImageRemoved] = useState(false);
  const [isCoverImageRemoved, setIsCoverImageRemoved] = useState(false);

  const [familyMembers, setFamilyMembers] = useState<FamilyMember[]>([
    {
      userId: "",
      relationship: "",
      name: "",
      emailId: "",
      searchQuery: "",
      searchResults: [],
    },
  ]);

  const addFamilyMember = () => {
    setFamilyMembers([
      ...familyMembers,
      {
        userId: "",
        relationship: "",
        name: "",
        emailId: "",
        searchQuery: "",
        searchResults: [],
      },
    ]);
  };

  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const getLocationData = async (searchText: any, noData: any) => {
    try {
      const resp = await request(
        `/member/current/cities?searchTerm=${searchText}`
      );
      if (resp?.length === 0) {
        setLocationObj((prev: any) => ({
          ...prev,
          [noData]: true,
        }));
      } else {
      }
      if (resp?.length) {
        setLocationData(resp); // Update the state directly
      }
    } catch (error) {
      console.error("Error fetching states data:", error);
    }
  };

  const searchFamilyMembers = async (index: number) => {
    const searchQuery = familyMembers[index].searchQuery;
    const initilsearch = searchQuery.trim();
    try {
      if (initilsearch.length > 3) {
        const response = await request(`/member/${initilsearch}`);

        if (response) {
          const data = await response;
          setSearchResults(data);
          const updatedFamilyMembers = [...familyMembers];
          if (searchQuery?.length > 0) {
            updatedFamilyMembers[index].searchResults = data;
          } else {
            updatedFamilyMembers[index].searchResults = [];
            updatedFamilyMembers[index].searchQuery = "";
          }
          setFamilyMembers(updatedFamilyMembers);
        } else {
          console.error("API request failed");
        }
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleNameSelect = (name: string, index: number) => {
    const updatedFamilyMembers = [...familyMembers];
    updatedFamilyMembers[index].userId = name;
    setFamilyMembers(updatedFamilyMembers);
  };

  useEffect(() => {
    if (user !== "true" && loginVia === "phone") {
      const res = getToken();
    }
  }, []);

  const [form]: any = Form.useForm();

  useEffect(() => {
    const getProfileData = async () => {
      try {
        if (userExist === "true") {
          const resp = await request(`/member/current/me`);

          if (resp) {
            console.log("resp--cc", resp);
            localStorage.setItem("profileColorCode", resp?.profileColorCode);
            setProfile(resp);
            setLocationObj((prev: any) => ({
              ...prev,
              currentState: resp?.member?.currentState,
              currentCity: resp?.member?.currentCity,
              originState: resp?.member?.originState,
              originCity: resp?.member?.originCity,
            }));
            form.setFieldsValue(resp);
            form.resetFields();
            setLoading(false);
            setProfileImage(resp?.profileUrl);
            setBannerImage(resp?.bannerUrl);
            setSelectedStatus(resp?.member?.status);
            setProfileScore(resp?.profileScore);
            if (resp && resp?.relationships) {
              const familyMembersData = resp?.relationships?.map(
                (relationship: any) => ({
                  searchQuery: "", // Set your default value here
                  userId: relationship?.userId,
                  name: relationship?.name,
                  emailId: relationship?.email,
                  relationship: relationship?.relation,
                  searchResults: [],
                  // Add other properties as needed
                })
              );

              setFamilyMembers(familyMembersData);
            }
          } else {
            setLoading(false);
          }
        } else {
          setProfile({});
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
      }
    };
    getProfileData();
  }, [form]);

  const HandleClick = () => {
    hiddenFileInput?.current?.click();
  };

  const HandleBannerClick = () => {
    hiddenBannerFileInput?.current?.click();
  };

  const genderOptions = [
    { label: "Female", value: "FEMALE" },
    { label: "Male", value: "MALE" },
    { label: "Other", value: "PREFER_NOT_TO_SAY" },
  ];

  const handleNameChange = (value: any) => {
    if (value.length === 0) {
      setUsernameError("Enter Name");
    } else if (!/^[a-zA-Z\s]*$/.test(value)) {
      setUsernameError("Special characters are not allowed");
    } else {
      setUsernameError("");
    }
  };

  // console.log("USERNAME",usernameError)

  const CelebrityNameCheck = async (name: any) => {
    const payload = {
      method: "POST",
      body: JSON.stringify({
        name,
      }),
    };

    const celebrityNameCheckResponse = await request(
      `/member/current/celebrity-name/check`,
      payload
    );

    const res = await celebrityNameCheckResponse?.nameMatch;
    setIsCelebrityName(celebrityNameCheckResponse?.nameMatch);

    // await form.validateFields(["name1"]);
    console.log("res", res);
    return res;
  };

  const onUpdateClick = async (values: any) => {
    const celebrityResp = await CelebrityNameCheck(values?.name1);
    if (celebrityResp) {
      // await form.validateFields(["name1"]);
      toast.error(
        "Please refrain from using the names of well-known public figures"
      );
      return;
    } else {
      setUpdateLoading(true);
      const res = await getToken();
      let imageFormData = new FormData();
      if (selectedFiles) {
        selectedFiles.forEach((item) => {
          imageFormData.append(item[0], new Blob([item[1]]), item[1]?.name);
        });
      }

      const relationships = familyMembers
      .filter((member): member is FamilyMember => 
        member !== null && 
        typeof member === 'object' &&
        'userId' in member &&
        'relationship' in member &&
        typeof member.userId === 'string' &&
        typeof member.relationship === 'string' &&
        member.userId.length > 0 && 
        member.relationship.length > 0
      )
      .map((member) => ({
        userId: member.userId,
        relation: member.relationship,
      }));

      // const blob = new Blob(
      //   [
      //     JSON.stringify(
        const payloadObj: PayloadType = {
            email:
              values?.email?.toLowerCase() ||
              profile?.member?.email?.toLowerCase(),
            role: "MEMBER",
            firebaseUID: localStorage.getItem("firebaseId"),
            signUpVia:
              loginVia === "email"
                ? "IN_APP_EMAIL"
                : loginVia === "phone"
                ? "IN_APP_PHONE"
                : "GOOGLE",
            type:
              values?.type?.toUpperCase() ||
              profile?.member?.type?.toUpperCase(),
            workingType:
              values?.status?.toUpperCase() || profile.status?.toUpperCase(),
            phoneNumber:
              values?.phoneNumber.replace(/\D/g, "") ||
              profile?.member?.phoneNumber.replace(/\D/g, ""),
            currentLocation: values?.place || profile?.currentLocation,

            showMatrimonyProfiles:
              values?.showMatrimonyProfiles1 !== null
                ? values?.showMatrimonyProfiles1
                : profile?.showMatrimonyProfiles,
            showFamilyTree:
              values?.showFamilyTree1 !== null
                ? values?.showFamilyTree1
                : profile?.showFamilyTree,
            interests: values?.interests || profile?.interests,
            member: {
              name: values?.name1 || profile?.member?.name,
              // currentState: values?.state || "KARNATAKA",
              // currentCity: values?.city || "BANGALORE",
              currentState: locationObj?.currentState,
              currentCity: locationObj?.currentCity,
              originState: locationObj?.originState,
              originCity: locationObj?.originCity,
              place: values?.originallyFrom || profile?.member?.place,
              dob: values?.dob1.add(330, "minutes") || profile?.member?.dob,
              gender: values?.gender || profile?.member?.gender,
              relationships: relationships,
              religion: values?.religion || profile?.member?.religion,
              caste: values?.caste || profile?.member?.caste,
              subCaste: values?.subCaste || profile?.member?.subCaste,
              familyName: values?.familyName || profile?.member?.familyName,
              workplace: values?.workplace || profile?.workplace,
              occupation: values?.occupation || profile?.occupation,
              university: values?.university || profile?.university,
            },
          }
        // Conditionally add profileUrl only if isProfileImageRemoved is true
      if (isProfileImageRemoved) {
        payloadObj.profileUrl = null;
      }
        // Conditionally add profileUrl only if isCoverImageRemoved is true
      if (isCoverImageRemoved) {
        payloadObj.bannerUrl = null;
      }

      const blob = new Blob(
        [JSON.stringify(payloadObj)],
        {
          type: "application/json",
        }
      );
      imageFormData.append("data", blob);

      try {
        let url =
          userExist === "true" ? "/member/current" : "/member/current/create";
        const resp =
          userExist === "true"
            ? await multiFormRequest(`${url}`, imageFormData, "put")
            : await multiFormRequest(`${url}`, imageFormData, "post");
        if (resp?.status === 200) {
          const meApiResp = await request("/member/current/me");
          setProfileScore(meApiResp?.profileScore);
          if (meApiResp?.id) {
            localStorage.setItem("id", meApiResp?.id);
            localStorage.setItem("name", meApiResp?.member.name);
            localStorage.setItem(
              "profileColorCode",
              meApiResp?.profileColorCode
            );
            localStorage.setItem("profileUrl", meApiResp?.profileUrl);
            localStorage.setItem("profileScore", meApiResp?.profileScore);
            localStorage.setItem("userExist", "true");
            localStorage.setItem(
              "profileColorCode",
              meApiResp?.profileColorCode
            );
            if (meApiResp?.profileScore === 100) {
              navigate("/feeds");
            }
            setUpdateLoading(false);
          }

          {
            userExist === "true"
              ? toast.success("Profile updated succesfully!")
              : toast.success("Profile created succesfully!");
          }
        } else {
          setUpdateLoading(false);
          userExist === "true"
            ? toast.error(
                "Profile updation failed. Please try again after sometime."
              )
            : toast.error("Profile creation failed");
        }
      } catch (error: any) {
        setUpdateLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };

  const onImageChange = (
    event: ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    const files = Array.from(event.target.files ?? []);
    if (files && files[0]) {
      setImageLoading(true);

      if (
        !(
          files[0]?.type?.includes("image/") ||
          files[0]?.type?.includes("video/")
        )
      ) {
        setImageLoading(false);
        toast.error("Invalid format");
        return;
      }
      if (files[0].size > 1024 * 1024) {
        new Compressor(files[0], {
          quality: 0.6,
          success: (result: any) => {
            if (type === "profileImage") {
              setProfileImage(URL.createObjectURL(result)); // Update profile image URL
              const updatedFiles = selectedFiles.filter(
                ([fileType]) => fileType !== type
              );
              updatedFiles.push([type, result]);
              setSelectedFiles(updatedFiles);
            } else if (type === "bannerImage") {
              setBannerImage(URL.createObjectURL(result)); // Update banner image URL
              const updatedFiles = selectedFiles.filter(
                ([fileType]) => fileType !== type
              );
              updatedFiles.push([type, result]);
              setSelectedFiles(updatedFiles);
            }
            setImageLoading(false);
          },
          error: (err: any) => {
            setImageLoading(false);
            console.log(err.message);
            toast.error("File upload failed. Please try again later.");
          },
        });
      } else {
        if (type === "profileImage") {
          setProfileImage(URL.createObjectURL(files[0])); // Update profile image URL
          const updatedFiles = selectedFiles.filter(
            ([fileType]) => fileType !== type
          );
          updatedFiles.push([type, files[0]]);
          setSelectedFiles(updatedFiles);
        } else if (type === "bannerImage") {
          setBannerImage(URL.createObjectURL(files[0])); // Update banner image URL
          const updatedFiles = selectedFiles.filter(
            ([fileType]) => fileType !== type
          );
          updatedFiles.push([type, files[0]]);
          setSelectedFiles(updatedFiles);
        }
        setImageLoading(false);
      }
    }
  };

  const receivedDOB = profile?.member?.dob;
  let formattedDOB = null;

  if (receivedDOB) {
    const datePart = receivedDOB.split("T")[0];
    formattedDOB = datePart;
  }
  const initialFamilyMembers: FormFieldNames = familyMembers.reduce(
    (initialValues, member, index) => {
      const fieldName = `relationshipTypeEnum${index}`;
      initialValues[fieldName] = member.relationship || "";
      return initialValues;
    },
    {} as FormFieldNames
  );
  const initialFamily: FormFieldNames = familyMembers.reduce(
    (initialValues, member, index) => {
      const fieldName = `familyMember${index}`;
      initialValues[fieldName] = member?.name || member?.emailId?.toLowerCase();
      return initialValues;
    },
    {} as FormFieldNames
  );

  const [selectedReligion, setselectedReligion] = useState("");
  const [selectedCaste, setselectedCaste] = useState("");
  const [religionData, setReligionData] = useState<any>();
  const [casteData, setCasteData] = useState<any>();
  const [noCaste, setNoCaste] = useState<boolean>(false);
  const [subcasteData, setSubcasteData] = useState<any>();
  const [selectedSubcaste, setselectedSubcaste] = useState("");
  const [religionDataApi, setReligionDataApi] = useState<boolean>(false);
  const [casteDataApi, setCasteDataApi] = useState<boolean>(false);
  const [tagOptions, setTagOptions] = useState<any>([]);
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [locationObj, setLocationObj] = useState<any>({
    currentState: "",
    currentCity: "",
    originState: "",
    originCity: "",
    livesInDropDown: false,
    originallyFromDropDown: false,
  });
  let functionIterate = 0;

  const handleSearch = async (searchTerm: any) => {
    setSearchTerm(searchTerm);
    const tagsResp = await request(`/member/tags?searchTerm=${searchTerm}`);
    setTagOptions(tagsResp ?? []);
  };

  const handleChange = (value: any) => {
    // if (value.length <= 3) {
    setSelectedTags(value);
    handleSearch("");
    // }
  };

  const getReligionData = async () => {
    const resp = await request(`/member/current/matrimony-profile/religion`);
    if (resp?.length) {
      const newCommunityList = resp;
      setReligionData(newCommunityList);
      setReligionData((prevState: any) => [
        ...prevState,
        { id: "noPreferences", name: "noPreferences" },
      ]);
    } else {
      setNoCaste(true);
      console.log("Something goes wrong");
    }
  };

  const findKeyValue = (data: any, key: any) => {
    let res = data?.find(
      (item: any) =>
        item?.name?.toUpperCase() === key?.toUpperCase() ||
        item?.id?.toUpperCase() === key?.toUpperCase()
    );
    return res;
  };

  console.log("setLocationObj", locationObj);

  const deleteUser = async () => {
    setDeleteLoading(true);
    const payload = { method: "POST", body: JSON.stringify({}) };
    const response = await request(
      `/member/current/user/account/delete`,
      payload
    );
    functionIterate++;
    if (response === true) {
      toast.success("Account has been deleted successfully!");
      setDeleteLoading(false);
      navigate("/login");
      localStorage.clear();
    } else if (response?.status === 400 && functionIterate < 3) {
      deleteUser();
    } else {
      toast.error("Failed to delete account. Please try again later.");
      setDeleteLoading(false);
      console.log("Something goes wrong");
    }
  };

  const getCasteData = async (value: any) => {
    const selectedReligion1 = religionData?.find(
      (religion: any) => religion?.name === value
    );
    const resp = await request(
      `/member/current/matrimony-profile/caste/${selectedReligion1?.religionId}`
    );
    if (resp?.length) {
      const newCommunityList = resp;
      setCasteData(newCommunityList);
      if (profile?.member?.subCaste) {
        const selectedcaste1 = newCommunityList?.find(
          (religion: any) => religion?.name === profile?.member?.caste
        );
        const resp = await request(
          `/member/current/matrimony-profile/sub-caste/${selectedcaste1?.casteId}`
        );
        if (resp?.length) {
          const newCommunityList = resp;
          setSubcasteData(newCommunityList);
        } else {
          console.log("Something goes wrong");
        }
      }
    } else {
      console.log("Something goes wrong");
      setNoCaste(true);
      setCasteData([]);
    }
  };
  const getSubcasteData = async (value: any) => {
    const selectedcaste1 = casteData?.find(
      (religion: any) => religion?.name === value
    );
    const resp = await request(
      `/member/current/matrimony-profile/sub-caste/${selectedcaste1?.casteId}`
    );
    if (resp?.length) {
      const newCommunityList = resp;
      setSubcasteData(newCommunityList);
    } else {
      console.log("Something goes wrong");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getReligionData();
      if (profile?.member?.religion) {
        setselectedReligion(profile?.member?.religion);
        setReligionDataApi(!religionDataApi);
      }
    };
    fetchData();
  }, [profile?.member]);
  useEffect(() => {
    if (profile?.member?.religion && religionData) {
      setCasteDataApi(!casteDataApi);
      getCasteData(profile?.member?.religion);
    }
  }, [religionDataApi]);

const removeProfileImage = () => {
  setProfileImage(null);
  setSelectedFiles(selectedFiles.filter(([fileType]) => fileType !== "profileImage"));
  setIsProfileImageRemoved(true);
};
const removeBannerImage = () => {
  setBannerImage(null);
  setSelectedFiles(selectedFiles.filter(([fileType]) => fileType !== "bannerImage"));
  setIsCoverImageRemoved(true);
};

  const profileMenu = (
    <Menu>
      <Menu.Item key="1" onClick={HandleClick}>
        Change Profile Picture
      </Menu.Item>
      <Menu.Item key="2" onClick={removeProfileImage}>
        Remove Picture
      </Menu.Item>
    </Menu>
  );
  const bannerMenu = (
    <Menu>
      <Menu.Item key="1" onClick={HandleBannerClick}>
        Change Cover photo
      </Menu.Item>
      <Menu.Item key="2" onClick={removeBannerImage}>
        Remove photo
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {loading ? (
        <div className="loader mt-32">
          <Loader />{" "}
        </div>
      ) : (
        <Modal
          title={
            <div
              className="modalHeader"
              style={{
                borderBottomWidth: "1px",
                borderBottomColor: "rgba(0,0,0,0.2)",
              }}
            >
              {userExist === "true" ? "Edit Profile" : "Create Profile"}
            </div>
          }
          closeIcon={
            <div className="popConfirm">
              {userExist === "false" ||
              userExist === null ||
              profileScore !== 100.0 ? (
                <div onClick={() => setExitModalVisible(true)}>
                  <div className="bi bi-x text-4xl antdModalClose"></div>
                </div>
              ) : (
                <div
                  className="bi bi-x text-4xl antdModalClose"
                  onClick={() => {
                    onClose();
                  }}
                ></div>
              )}
            </div>
          }
          footer={null}
          className="w-full top-5"
          open
          width={750}
        >
          <div className={`${updateLoading ? "blurLoader" : ""}`}>
            {(updateLoading || imageLoading || deleteLoading) && (
              <div className="loader">
                <Modal className="loaderModal" open centered footer>
                  <Loader />
                </Modal>
              </div>
            )}
            <div className="flex flex-col profileModal">
              <div className="flex flex-col ">
                <div className="ProfileImage flex flex-col">
                  <div
                    className="flex flex-row justify-between items-center w-full  pb-2 editButton"
                    style={{
                      borderBottomWidth: "1px",
                      borderBottomColor: "rgba(0,0,0,0.1)",
                    }}
                  >
                    <div className="formHeading">Profile picture</div>
                    <Dropdown overlay={profileMenu} trigger={['click']}>
          <a className=" font-semibold text-[#676F2E]" onClick={e => e.preventDefault()}>
            EDIT
          </a>
        </Dropdown>
                  </div>
                  <div className="flex items-center flex-col py-5">
                    <button
                      className="w-[175px] h-[175px] rounded-full"
                      onClick={HandleClick}
                    >
                      {profileImage ? (
                        <img
                          className="w-[175px] h-[175px] text-9xl text-gray-400 rounded-full object-cover"
                          alt="Profile preview"
                          src={profileImage}
                        />
                      ) : (
                        <i className="bi bi-camera-fill w-[150px] h-[150px] text-9xl text-gray-400"></i>
                      )}
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        window.postMessage({ type: "INPUT_CLICKED" }, "*");
                        onImageChange(e, "profileImage");
                      }}
                      ref={hiddenFileInput}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
                <div className="bannerImage flex flex-col">
                  <div
                    className="flex flex-row justify-between items-center w-full pt-3 pb-2 editButton"
                    style={{
                      borderBottomWidth: "1px",
                      borderBottomColor: "rgba(0,0,0,0.1)",
                    }}
                  >
                    <div className="formHeading">Cover photo</div>
                    <Dropdown overlay={bannerMenu} trigger={['click']}>
          <a className=" font-semibold text-[#676F2E]" onClick={e => e.preventDefault()}>
            EDIT
          </a>
        </Dropdown>
                  </div>
                  <div className="flex items-center flex-col py-5 px-5">
                    <button
                      className="w-full h-[200px] rounded-lg"
                      onClick={HandleBannerClick}
                    >
                      {bannerImage ? (
                        <img
                          className="w-full h-[200px] text-9xl text-gray-400 rounded-lg object-cover"
                          alt="Banner preview"
                          src={bannerImage}
                        />
                      ) : (
                        <i className="bi bi-camera-fill w-[150px] h-[150px] text-9xl text-gray-400"></i>
                      )}
                    </button>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => onImageChange(e, "bannerImage")}
                      ref={hiddenBannerFileInput}
                      style={{ display: "none" }}
                    />
                  </div>
                </div>
              </div>
              <div className="flex">
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#7d8742",
                      borderRadius: 10,
                      colorPrimaryBorder: "#7d8742",
                    },
                  }}
                >
                  <Form
                    initialValues={{
                      name1: profile?.member?.name || "",
                      gender: profile?.member?.gender || "",
                      dob1:
                        (profile?.member?.dob &&
                          dayjs(formattedDOB, "YYYY-MM-DD")) ||
                        // dayjs(date18YearsAgo) ||
                        "",
                      email: profile?.email || email,
                      phoneNumber:
                        profile?.phoneNumber?.replace(/\D/g, "") ||
                        profile?.phoneNumber?.replace(/\D/g, "") ||
                        phoneNumber?.replace(/\D/g, ""),
                      type: profile?.type || "",
                      city: profile?.member?.currentCity?.length
                        ? `${profile?.member?.currentCity || ""} - ${
                            profile?.member?.currentState || ""
                          } ` || ""
                        : "",
                      state: profile?.member?.originCity?.length
                        ? `${profile?.member?.originCity} - ${profile?.member?.originState}` ||
                          ""
                        : "",
                      workplace: profile?.member?.workplace || "",
                      occupation: profile?.member?.occupation || "",
                      university: profile?.member?.university || "",
                      showMatrimonyProfiles1:
                        profile?.showMatrimonyProfiles || false,
                      interests:
                        profile?.interests?.length ||
                        profile?.profileScore === 100
                          ? profile?.interests
                          : ["News", "Family"],
                      showFamilyTree1: profile?.showFamilyTree || false,
                      religion: profile?.member?.religion || "",
                      caste: profile?.member?.caste || "",
                      subCaste: profile?.member?.subCaste || "",
                      familyName: profile?.member?.familyName || "",
                      profile,
                      initialFamilyMembers,
                    }}
                    onFinish={(values) => {
                      onUpdateClick(values);
                    }}
                    form={form}
                    name="updateProfile"
                    className="bg-white w-full profileForm"
                  >
                    <div
                      className="flex flex-row justify-between items-center w-full pt-3 pb-2 "
                      onClick={() => setShowContactInfo(!showContactInfo)}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomColor: "rgba(0,0,0,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <div className="formHeading">Contact and Basic Info</div>
                      <div className="">
                        {showContactInfo ? (
                          <UpOutlined className="text-md" />
                        ) : (
                          <DownOutlined className="text-md" />
                        )}
                      </div>
                    </div>
                    {showContactInfo && (
                      <div className="px-5 py-3">
                        <div className="contactForm">
                          <Form.Item
                            validateStatus={usernameError ? "error" : ""}
                            help={usernameError}
                            label={
                              <div className="flex flex-row items-center">
                                <span className="text-2xl mr-2">
                                  <i className="bi bi-person"></i>
                                </span>
                                <span className="text-md">Name</span>
                              </div>
                            }
                            name="name1"
                            className="text-base font-medium text-lg"
                            rules={[
                              { required: true, message: "Enter Name" },
                              // {
                              //   validator(_, value: any) {
                              //     console.log("i am here");
                              //     if (isCelebrityName) {
                              //       console.log("i am in iffffff");
                              //       return Promise.reject(
                              //         "The use of celebrity names is prohibited"
                              //       );
                              //     } else {
                              //       console.log("i am in else");
                              //       return Promise.resolve();
                              //     }
                              //   },
                              // },
                            ]}
                          >
                            <Input
                              // onBlur={(e) => {
                              //   CelebrityNameCheck(e?.target?.value);
                              // }}
                              onChange={(e) => {
                                handleNameChange(e.target.value);
                              }}
                              name="name1"
                              defaultValue={profile?.member?.name || ""}
                              placeholder="Enter Name"
                              size="middle"
                              style={{ width: "90%" }}
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <div className="flex flex-row items-center">
                                <span className="text-2xl mr-2">
                                  <i className="bi bi-person"></i>
                                </span>
                                <span className="text-md"> Gender</span>
                              </div>
                            }
                            name="gender"
                            className="text-base font-medium text-lg"
                            rules={[
                              { required: true, message: "Enter Gender" },
                            ]}
                          >
                            <Radio.Group
                              options={genderOptions}
                              defaultValue={profile?.member?.gender}
                              size="large"
                            />
                          </Form.Item>
                        </div>
                        <div className="contactForm">
                          <Form.Item
                            label={
                              <div className="flex flex-row items-center">
                                <span className="text-2xl mr-2">
                                  <i className="bi bi-envelope"></i>
                                </span>
                                <span className="text-md"> Email</span>
                              </div>
                            }
                            name="email"
                            className="text-base font-medium text-lg"
                            rules={[
                              {
                                type: "email",
                                message: "The input is not valid a E-mail!",
                              },
                              {
                                required: true,
                                message: "Please enter your E-mail!",
                              },
                            ]}
                          >
                            <Input
                              name="email"
                              style={{ width: "90%" }}
                              disabled={
                                loginVia === "email" ||
                                loginVia === "gmail" 
                                // || profile?.emailVerified
                              }
                              defaultValue={profile?.email || ""}
                              placeholder="Enter mail id"
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <div className="flex flex-row items-center">
                                <span className="text-2xl mr-2">
                                  <i className="bi bi-telephone"></i>
                                </span>
                                <span className="text-md"> Phone</span>
                              </div>
                            }
                            name="phoneNumber"
                            className="text-base font-medium text-lg"
                            rules={[
                              { required: true, message: "Enter Phonenumber" },
                            ]}
                            // rules={[
                            //   {
                            //     validator(rule, value) {
                            //       return new Promise((resolve, reject) => {
                            //         if (
                            //           value?.length === 10 ||
                            //           phoneNumber?.length
                            //         ) {
                            //           resolve("");
                            //         } else {
                            //           reject("Enter 10 digit number");
                            //         }
                            //       });
                            //     },
                            //   },
                            // ]}
                          >
                            {/* <Input
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              disabled={loginVia === "phone"}
                              defaultValue={profile?.phoneNumber || phoneNumber}
                              className="h-9"
                              placeholder="Add Phone Number"
                              max={10}
                            /> */}
                            <div className="flex flex-col gap-5 Profilephoneinputwrapper">
                              {/* <label className="phoneTitle">
                {" "}
                {expandForm
                  ? "Verify your phone number"
                  : "Enter your phone Number"}
              </label> */}
                              <PhoneInput
                                country={"in"}
                                value={profile?.phoneNumber || phoneNumber}
                                disabled={loginVia === "phone"}
                                // onChange={(phone) => setPhoneNumber(phone)}
                              />
                            </div>
                          </Form.Item>
                        </div>
                        <div className="flex flex-row items-center">
                          <Form.Item
                            name="dob1"
                            label={
                              <div className="flex flex-row items-center">
                                <span className="text-2xl mr-2">
                                  <i className="bi bi-cake2"></i>
                                </span>
                                <span className="text-md"> DoB</span>
                              </div>
                            }
                            className="text-base font-medium text-lg"
                            rules={[
                              {
                                required: true,
                                message: "Enter Date of Birth",
                              },
                            ]}
                          >
                            <DatePicker
                              name="dob1"
                              className="w-full"
                              format={"DD-MM-YYYY "}
                              disabledDate={disabledDate}
                              placeholder="Add Date of Birth"
                              style={{ width: "95%" }}
                              defaultPickerValue={dayjs(date18YearsAgo)}
                            />
                          </Form.Item>
                          <Form.Item
                            className="text-base font-medium text-lg p-2 m-2"
                            wrapperCol={{ offset: 0, span: 16 }}
                            label="Profile type"
                            name="type"
                            rules={[
                              {
                                required: true,
                                message: "Select profile type",
                              },
                            ]}
                          >
                            <Select className="communityDropDown1 popupWidth1 type-select">
                              <Select.Option
                                className="selectOptions"
                                value="PRIVATE"
                              >
                                Private
                              </Select.Option>
                              <Select.Option
                                className="selectOptions"
                                value="PUBLIC"
                              >
                                Public
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </div>
                        <div className="flex flex-row items-center justify-between desktop-only">
                        <div className="flex flex-col justify-center items-start w-[100%]"> 
                                <span className="font-medium " style={{fontSize:"15px"}}>
                                  Show my matrimony profiles
                                </span>
                                <span className="text-gray-500" style={{fontSize:"0.735rem",fontWeight:"normal"}}>
      Show or hide your matrimony profiles on your public profile
    </span> 
                              </div>
                          <Form.Item
                            name="showMatrimonyProfiles1"
                            className="text-base font-medium text-lg"
                            rules={[
                              {
                                required: true,
                                message:
                                  "select if you want to show matrimony profiles",
                              },
                            ]}
                          >
                            <div style={{display:"flex", justifyContent:"flex-end", flexDirection:"row"}}>
                            <Switch
                              checkedChildren="Yes"
                              unCheckedChildren="No"
                              className="communityDropDown1 popupWidth1 w-14 bg-[#804d31]"
                              defaultChecked={profile?.showMatrimonyProfiles}
                            />
                            </div>
                          </Form.Item>
                        </div>
                      </div>
                    )}
                    <div
                      className="flex flex-row justify-between items-center w-full pb-2 "
                      onClick={() => setShowintro(!showIntro)}
                      style={{
                        borderBottomWidth: "1px",
                        borderBottomColor: "rgba(0,0,0,0.1)",
                        cursor: "pointer",
                      }}
                    >
                      <div className="formHeading">Customize your Intro</div>
                      <div className="" style={{ cursor: "pointer" }}>
                        {showIntro ? (
                          <UpOutlined className="text-md" />
                        ) : (
                          <DownOutlined className="text-md" />
                        )}
                      </div>
                    </div>
                    {showIntro && (
                      <div className="px-5 pt-3">
                        <span className="formHeading">Current Town/City</span>
                        <div>
                          <div className="contactForm py-3">
                            <Form.Item
                              label={
                                <div className="flex flex-row items-center">
                                  <span className="text-2xl mr-2 font-bold">
                                    <i className="bi bi-geo-alt"></i>
                                  </span>
                                  <span className="text-md"> Lives In</span>
                                </div>
                              }
                              name="city"
                              className="text-base font-medium text-lg"
                              rules={[
                                {
                                  required: true,
                                  message: "Select current location",
                                },
                              ]}
                            >
                              {/* <Input
                                defaultValue={profile?.member?.place}
                                className=""
                                style={{ width: "90%", fontWeight: "normal" }}
                              /> */}

                              <Select
                                notFoundContent={
                                  locationObj?.livesInDropDown
                                    ? "No Data Found"
                                    : "Please start typing"
                                }
                                onChange={(value) => {
                                  const obj = findKeyValue(livesInData, value);
                                  setLocationObj((prev: any) => ({
                                    ...prev,
                                    currentState: obj?.stateName,
                                    currentCity: obj?.name,
                                  }));
                                }}
                                placeholder="Select Current lives in city"
                                showSearch
                                onSearch={(text) => {
                                  if (text?.length > 3) {
                                    getLocationData(text, "livesInDropDown");
                                  }
                                }}
                              >
                                {livesInData?.map((item: any) => {
                                  return (
                                    <Select.Option
                                      key={item?.id}
                                      value={item?.name}
                                    >
                                      <div>
                                        {item?.name} - {item?.stateName}
                                      </div>
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              label={
                                <div className="flex flex-row items-center">
                                  <span className="text-2xl mr-2">
                                    <i className="bi bi-house-door"></i>
                                  </span>
                                  <span className="text-md">
                                    {" "}
                                    Originally from
                                  </span>
                                </div>
                              }
                              name="state"
                              className="text-base font-medium text-lg"
                              rules={[
                                {
                                  required: true,
                                  message: "Select originally from",
                                },
                              ]}
                            >
                              {/* <Input
                                name="originallyFrom"
                                style={{ fontWeight: "normal" }}
                              /> */}

                              <Select
                                notFoundContent={
                                  locationObj?.originallyFromDropDown
                                    ? "No Data Found"
                                    : "Please start typing"
                                }
                                onChange={(value) => {
                                  console.log("originally  in", value);
                                  const obj = findKeyValue(livesInData, value);
                                  console.log("obj", obj);
                                  setLocationObj((prev: any) => ({
                                    ...prev,
                                    originState: obj?.stateName,
                                    originCity: obj?.name,
                                  }));
                                }}
                                placeholder="Select Originally from city"
                                showSearch
                                onSearch={(text) => {
                                  if (text?.length > 3) {
                                    getLocationData(
                                      text,
                                      "originallyFromDropDown"
                                    );
                                  }
                                }}
                              >
                                {livesInData?.map((item: any) => {
                                  return (
                                    <Select.Option
                                      key={item?.id}
                                      value={item?.name}
                                    >
                                      <div>
                                        {item?.name} - {item?.stateName}
                                      </div>
                                    </Select.Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <span className="formHeading">Your Interests</span>
                        <p className="text-sm text-gray-600">Select your interests to personalize the content you see in your feed.</p>
                        <div className="py-3">
                          <div>
                            <div className="flex flex-col">
                              <div className="antdInterests">
                                <Form.Item
                                  label={!isMobile &&
                                    <div className="flex flex-row items-center">
                                      <span className="text-2xl mr-2">
                                        <i className="bi bi-briefcase"></i>
                                      </span>
                                      <span className="text-md">
                                        {" "}
                                        Interests
                                      </span>
                                    </div>
                                  }
                                  name="interests"
                                  className="text-base font-medium text-lg"
                                >
                                  <Select
                                    className="communityDropDown1 popupWidth1 type-select "
                                    showSearch
                                    allowClear
                                    onChange={handleChange}
                                    mode="multiple"
                                    // maxTagCount={"responsive"}

                                    onSearch={handleSearch}
                                    autoClearSearchValue={true}
                                    notFoundContent={
                                      searchTerm?.length > 0 ? (
                                        <span style={{ color: "gray" }}>
                                          Tag not Found
                                        </span>
                                      ) : (
                                        <span style={{ color: "gray" }}>
                                          Search tags
                                        </span>
                                      )
                                    }
                                  >
                                    {tagOptions?.map((group: any) => (
                                      // <Select.OptGroup
                                      //   key={group.label}
                                      //   label={group.label}
                                      // >
                                      //   {group.options.map((option) => (
                                      <Option
                                        key={group.name}
                                        value={group.name}
                                        // disabled={selectedTags.length >= 3 && !selectedTags.includes(group.name)}
                                      >
                                        <div className="flex">{group.name}</div>
                                      </Option>
                                      //   ))}
                                      // </Select.OptGroup>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            </div>
                          </div>
                        </div>
                        <span className="formHeading">Work</span>
                        <div className="py-3">
                          {addWork ||
                          profile?.member?.workplace ||
                          profile?.member?.occupation ? (
                            <div>
                              <div className="flex flex-col">
                                <div>
                                  <Form.Item
                                    label={
                                      <div className="flex flex-row items-center">
                                        <span className="text-2xl mr-2">
                                          <i className="bi bi-briefcase"></i>
                                        </span>
                                        <span className="text-md">
                                          {" "}
                                          Workplace
                                        </span>
                                      </div>
                                    }
                                    name="workplace"
                                    className="text-base font-medium text-lg"
                                  >
                                    <Input
                                      name="workplace"
                                      placeholder="Add Workplace"
                                      style={{ fontWeight: "normal" }}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className="flex flex-col">
                                <div>
                                  <Form.Item
                                    label={
                                      <div className="flex flex-row items-center">
                                        <span className="text-2xl mr-2">
                                          <i className="bi bi-suitcase-lg"></i>
                                        </span>
                                        <span className="text-md">
                                          {" "}
                                          Occupation
                                        </span>
                                      </div>
                                    }
                                    name="occupation"
                                    className="text-base font-medium text-lg"
                                  >
                                    <Input
                                      name="occupation"
                                      placeholder="Add Occupation"
                                      style={{ fontWeight: "normal" }}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div
                              className="flex flex-row items-center"
                              onClick={() => setAddWork(true)}
                            >
                              <span
                                className="text-2xl mr-2 "
                                style={{ cursor: "pointer" }}
                              >
                                <i className="bi bi-plus-circle"></i>
                              </span>
                              <span
                                className="text-md "
                                style={{ cursor: "pointer" }}
                              >
                                Add Work
                              </span>
                            </div>
                          )}
                        </div>

                        <span className="formHeading">Education</span>
                        <div className="py-3">
                          {addUniversity || profile?.member?.university ? (
                            <div>
                              <Form.Item
                                label={
                                  <div className="flex flex-row items-center">
                                    <span className="text-2xl mr-2">
                                      <i className="bi bi-mortarboard"></i>
                                    </span>
                                    <span className="text-md"> University</span>
                                  </div>
                                }
                                name="university"
                                className="text-base font-medium text-lg"
                              >
                                <Input
                                  name="university"
                                  placeholder="Add University"
                                  style={{ fontWeight: "normal" }}
                                />
                              </Form.Item>
                            </div>
                          ) : (
                            <div
                              className="flex flex-row items-center"
                              onClick={() => setAddUniversity(true)}
                            >
                              <span
                                className="text-2xl mr-2 "
                                style={{ cursor: "pointer" }}
                              >
                                <i className="bi bi-plus-circle"></i>
                              </span>
                              <span
                                className="text-md "
                                style={{ cursor: "pointer" }}
                              >
                                Add University
                              </span>
                            </div>
                          )}
                        </div>
                        <span className="formHeading">Personal Info</span>
                        <div className="pt-3">
                          {addFamilyName || profile?.member?.familyName ? (
                            <div>
                              <Form.Item
                                label={
                                  <div className="flex flex-row items-center">
                                    <span className="text-md">Family Name</span>
                                  </div>
                                }
                                name="familyName"
                                className="text-base font-medium text-lg"
                              >
                                <Input
                                  name="familyName"
                                  defaultValue={
                                    profile?.member?.familyName || ""
                                  }
                                  placeholder="Enter Family Name"
                                  size="middle"
                                  style={{
                                    width: "100%",
                                    fontWeight: "normal",
                                  }}
                                />
                              </Form.Item>
                            </div>
                          ) : (
                            <div
                              className="flex flex-row items-center"
                              onClick={() => setAddFamilyName(true)}
                            >
                              <span
                                className="text-2xl mr-2 "
                                style={{ cursor: "pointer" }}
                              >
                                <i className="bi bi-plus-circle"></i>
                              </span>
                              <span
                                className="text-md "
                                style={{ cursor: "pointer" }}
                              >
                                Add Family Name
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="py-3">
                          {addReligion || profile?.member?.religion ? (
                            <div>
                              <Form.Item
                                label={
                                  <div className="flex flex-row items-center">
                                    <span className="text-md"> Religion</span>
                                  </div>
                                }
                                name="religion"
                                className="text-base font-medium text-lg religion"
                              >
                                <Select
                                  showSearch
                                  placeholder="Please select religion"
                                  className="select-field overflow-hidden religionDropdown"
                                  onChange={(value) => {
                                    setselectedReligion(value);
                                    setselectedCaste("");
                                    setCasteData("");
                                    getCasteData(value);
                                  }}
                                  value={selectedReligion}
                                >
                                  {religionData?.map((religion: any) => (
                                    <Select.Option
                                      key={religion?.religionId}
                                      value={religion?.name}
                                    >
                                      {" "}
                                      {religion?.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </Form.Item>
                              {(selectedReligion || profile?.member?.caste) && (
                                <Form.Item
                                  label={
                                    <div className="flex flex-row items-center">
                                      <span className="text-md"> Caste</span>
                                    </div>
                                  }
                                  name="caste"
                                >
                                  {casteData?.length > 0 ? (
                                    <Select
                                      showSearch
                                      className="select-field overflow-hidden casteDropdown"
                                      placeholder="Please select caste"
                                      onChange={(value) => {
                                        setselectedCaste(value);
                                        getSubcasteData(value);
                                      }}
                                      value={selectedCaste}
                                    >
                                      {selectedReligion &&
                                        casteData?.map((caste: any) => (
                                          <Option
                                            key={caste?.id}
                                            value={caste?.name}
                                          >
                                            {" "}
                                            {caste?.name}
                                          </Option>
                                        ))}
                                    </Select>
                                  ) : (
                                    <Input placeholder="Please enter the Caste" />
                                  )}
                                </Form.Item>
                              )}
                              {(selectedCaste || profile?.member?.caste) && (
                                <Form.Item
                                  label={
                                    <div className="flex flex-row items-center">
                                      <span className="text-md">
                                        {" "}
                                        Sub Caste
                                      </span>
                                    </div>
                                  }
                                  name="subCaste"
                                >
                                  {subcasteData?.length > 0 ? (
                                    <Select
                                      showSearch
                                      className="select-field overflow-hidden"
                                      placeholder="Please select Subcaste"
                                      onChange={(value) => {
                                        setselectedSubcaste(value);
                                      }}
                                      value={selectedCaste}
                                    >
                                      {subcasteData?.map((caste: any) => (
                                        <Option
                                          key={caste?.id}
                                          value={caste?.name}
                                        >
                                          {" "}
                                          {caste?.name}
                                        </Option>
                                      ))}
                                    </Select>
                                  ) : (
                                    <Input
                                      placeholder="Please enter the Subcaste"
                                      onChange={(e: any) => {
                                        setselectedSubcaste(e.target.value);
                                      }}
                                    />
                                  )}
                                </Form.Item>
                              )}
                            </div>
                          ) : (
                            <div
                              className="flex flex-row items-center"
                              onClick={() => setAddReligion(true)}
                            >
                              <span
                                className="text-2xl mr-2 "
                                style={{ cursor: "pointer" }}
                              >
                                <i className="bi bi-plus-circle"></i>
                              </span>
                              <span
                                className="text-md "
                                style={{ cursor: "pointer" }}
                              >
                                Add Religion
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="flex flex-row justify-between">
                          <div className="flex flex-col justify-center">
                          <span className="formHeading">
                            Family and Relationships
                          </span>
                          <span className="text-gray-600 mr-2">
      Show or hide your family tree on your public profile
    </span> 
    </div>
                          <div className="flex flex-row items-center">
                            <Form.Item
                              name="showFamilyTree1"
                              className="text-base font-medium text-lg"
                            >
                              <Switch
                                checkedChildren={
                                  <i
                                    className="bi bi-globe-americas"
                                    style={{ fontSize: "16px" }}
                                  ></i>
                                }
                                unCheckedChildren={
                                  <i
                                    className="bi bi-lock-fill"
                                    style={{ fontSize: "15px" }}
                                  ></i>
                                }
                                className="communityDropDown1 popupWidth1 w-14 bg-[#804d31]"
                                defaultChecked={profile?.showFamilyTree}
                              />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="pt-5">
                          {familyMembers?.map((member, index) => {
                            return (
                              <div key={index}>
                                <Form.Item
                                  label={
                                    <div className="flex flex-row items-center">
                                      <span className="text-md">
                                        Family Member
                                      </span>
                                    </div>
                                  }
                                  name={`familyMember${index}`}
                                  className="text-base font-medium text-lg familymember"
                                >
                                  <Select
                                  allowClear
          showSearch
          value={member.emailId || member.searchQuery}
          defaultValue={initialFamily[`familyMember${index}`] || ""}
          placeholder="Select a person"
          onSearch={(value) => {
            const updatedFamilyMembers = [...familyMembers];
            updatedFamilyMembers[index].searchQuery = value;
            setFamilyMembers(updatedFamilyMembers);
            searchFamilyMembers(index);
          }}
          onChange={(value) => {
            if (!value) {
              const updatedFamilyMembers = [...familyMembers];
              updatedFamilyMembers[index].userId = "";
              updatedFamilyMembers[index].emailId = "";
              updatedFamilyMembers[index].name = "";
              updatedFamilyMembers[index].searchQuery = '';
              updatedFamilyMembers[index].searchResults = [];
              setFamilyMembers(updatedFamilyMembers);
            } else {
            const selectedOption = member.searchResults.find(
              (result:any) => result.email === value
            );
            if (selectedOption) {
              const updatedFamilyMembers = [...familyMembers];
              updatedFamilyMembers[index].userId = selectedOption.userId;
              updatedFamilyMembers[index].name = selectedOption.name;
              updatedFamilyMembers[index].emailId = selectedOption.email;
              updatedFamilyMembers[index].searchQuery = "";
              setFamilyMembers(updatedFamilyMembers);
            }
          }
          }}
          filterOption={false}
          notFoundContent={
            member.searchQuery?.length > 3 ? (
              <span className="text-slate-500">No User found</span>
            ) : (
              <span className="text-slate-500">
                Enter username or email ID to search user
              </span>
            )
          }
        >
                                    {member.searchResults?.filter((result: any) => 
    !familyMembers.some(
      (fm: any) => 
        fm.emailId === result.email || 
        fm.name === result.name
    )
  ).map(
                                      (result: any, resultIndex: any) => {
                                        return (
                                          <Select.Option
                                            key={result?.email}
                                            value={result?.email}
                                            label={result?.email}
                                          >
                                            <div className="flex flex-row items-center familymemberOption ">
                                              <div>
                                                {result?.profileUrl ? (
                                                  <img
                                                    className="text-9xl text-gray-400  dropdownProfileImage mr-2"
                                                    alt="preview"
                                                    src={result?.profileUrl}
                                                    style={{
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : (
                                                  <div
                                                    className={`capitalize relative inline-flex items-center items-center justify-center overflow-hidden mr-2`}
                                                    style={{
                                                      backgroundColor:
                                                        result?.profileColorCode,
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "50%",
                                                    }}
                                                  >
                                                    <span className="text-2xl text-white dark:text-gray-300">
                                                      {result?.name?.substring(
                                                        0,
                                                        1
                                                      )}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                              <div className="flex flex-col">
                                                <span className="dropdownName">
                                                  {result?.name}
                                                </span>
                                                <span className="dropdownEmail">
                                                  {result?.email}
                                                </span>
                                              </div>
                                            </div>
                                          </Select.Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </Form.Item>

                                <Form.Item
                                  className="text-base font-medium text-lg mt-7 relationship"
                                  wrapperCol={{ offset: 0, span: 16 }}
                                  label="Relationship"
                                  name={`relationshipTypeEnum${index}`}
                                  rules={[
                                    {
                                      message: "Select status",
                                    },
                                  ]}
                                  // initialValue={initialFamilyMembers}
                                >
                                  <Select
                                    className="communityDropDown1 popupWidth1 relationshipDropdown"
                                    defaultValue={
                                      initialFamilyMembers[
                                        `relationshipTypeEnum${index}`
                                      ] || ""
                                    }
                                    onChange={(value: any) => {
                                      const updatedFamilyMembers = [
                                        ...familyMembers,
                                      ];
                                      updatedFamilyMembers[index].relationship =
                                        value;
                                      setFamilyMembers(updatedFamilyMembers);
                                    }}
                                    allowClear
                                  >
                                    <Select.Option
                                      className="selectOptions"
                                      value="MOTHER"
                                    >
                                      Mother
                                    </Select.Option>
                                    <Select.Option
                                      className="selectOptions"
                                      value="FATHER"
                                    >
                                      Father
                                    </Select.Option>
                                    <Select.Option
                                      className="selectOptions"
                                      value="SPOUSE"
                                    >
                                      Spouse
                                    </Select.Option>
                                    <Select.Option
                                      className="selectOptions"
                                      value="DAUGHTER"
                                    >
                                      Daughter
                                    </Select.Option>
                                    <Select.Option
                                      className="selectOptions"
                                      value="SON"
                                    >
                                      Son
                                    </Select.Option>
                                    <Select.Option
                                      className="selectOptions"
                                      value="SISTER"
                                    >
                                      Sister
                                    </Select.Option>
                                    <Select.Option
                                      className="selectOptions"
                                      value="BROTHER"
                                    >
                                      Brother
                                    </Select.Option>
                                  </Select>
                                </Form.Item>
                              </div>
                            );
                          })}
                          <div
                            className="flex flex-row items-center"
                            onClick={addFamilyMember}
                            style={{ cursor: "pointer" }}
                          >
                            <span className="text-2xl mr-2">
                              <i className="bi bi-plus-circle"></i>
                            </span>
                            <span className="text-md">Add family member</span>
                          </div>
                        </div>
                        {selectedStatus === "employed" && (
                          <Form.Item
                            label="Workplace"
                            name="workplace"
                            className="text-base font-medium text-lg"
                          >
                            <Input name="workplace" />
                          </Form.Item>
                        )}

                        {selectedStatus === "student" && (
                          <Form.Item
                            label="University"
                            name="university"
                            className="text-base font-medium text-lg"
                          >
                            <Input name="university" />
                          </Form.Item>
                        )}

                        {selectedStatus === "retired" && (
                          <Form.Item
                            label="Ex Workplace"
                            name="exWorkplace"
                            className="text-base font-medium text-lg"
                          >
                            <Input name="exWorkplace" />
                          </Form.Item>
                        )}
                      </div>
                    )}
                    <div className="flex justify-between mt-4 width-full">
                      {profileScore === 100 && (
                        <div
                          className="flex w-full mt-1"
                          onClick={() => setDeleteClick(true)}
                        >
                          <span
                            className="flex items-center font-semibold px-2 deleteUserButton"
                            style={{
                              borderWidth: "1px",
                              borderColor: "#ff4d4f",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                          >
                            <DeleteOutlined className="pr-1" /> Delete account
                          </span>
                        </div>
                      )}
                      <Form.Item
                        label=""
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          // className={
                          //   isCelebrityName
                          //     ? `editProfilepopupButton disabled`
                          //     : "editProfilepopupButton"
                          // }
                          className={"editProfilepopupButton"}
                          type="primary"
                          htmlType="submit"
                          disabled={usernameError}
                        >
                          {userExist === "true" ? "Update" : "Create"}
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>
                </ConfigProvider>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Modal
        visible={exitModalVisible}
        open={exitModalVisible}
        closable={false}
        footer={false}
      >
        <div className="flex flex-col items-center">
          <div className="text-lg p-3 text-center">
            You need to complete your profile before using the app. If you exit
            now, you will be logged out. Are you sure you want to exit?{" "}
          </div>
          <div className="items-center">
            <Button
              key="cancel"
              onClick={() => setExitModalVisible(false)}
              className="m-2 ml-6 mt-3"
            >
              No
            </Button>

            <Button
              key="delete"
              type="primary"
              danger
              onClick={() => {
                navigate("/login");
                localStorage.clear();
                localStorage.removeItem("userExist");
              }}
              className="m-2"
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        visible={deleteClick}
        open={deleteClick}
        onCancel={() => setDeleteClick(false)}
        closable={true}
        footer={false}
        width={700}
        title={
          <div
            className="modalTitle flex flex-row items-center"
            style={{
              padding: "10px 0px 10px 10px",
              backgroundColor: "#ff4d4f",
              color: "white",
              borderRadius: "8px 8px 0 0",
            }}
          >
            <DeleteFilled />
            <span className="px-2">Delete account permanently</span>
          </div>
        }
      >
        <div
          className="flex flex-col items-center"
          style={{ padding: "0 0 20px 0" }}
        >
          <div className="text-lg p-3 text-left">
            <div className="flex flex-col">
              <span>Are you sure you want to delete your account?</span>
            </div>
            <div className="text-left pt-3">
              <span>
                {" "}
                By deleting your account, you agree to the following conditions:
              </span>
              <ul className="text-left list-disc pl-5 py-2">
                <li className="text-base">
                  All communities you are an admin of, will be deleted.
                </li>
                <li className="text-base">
                  All posts and comments you've created will be permanently
                  removed.
                </li>
                <li className="text-base">
                  All matrimony profiles you've created will be deleted.
                </li>
                <li className="text-base">
                  Your memberships in other communities will be terminated.
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center flex-col">
            <label className="flex items-center space-x-2">
              <Checkbox
                checked={isCheckboxChecked}
                onChange={(e) => setIsCheckboxChecked(e.target.checked)}
              />
              <span className="text-sm">
                I have read and agree to the above mentioned conditions.
              </span>
            </label>
            <div className="flex items-center justify-center">
              <Button
                key="cancel"
                onClick={() => setDeleteClick(false)}
                className="m-2 ml-6 mt-3"
              >
                No
              </Button>

              <Button
                key="delete"
                danger
                onClick={() => {
                  signOut(auth)
                    .then(() => {
                      deleteUser();
                    })
                    .catch((error) => {
                      console.log("ERROR", error);
                    });
                }}
                className="m-2 deleteYesButton"
                disabled={!isCheckboxChecked}
              >
                Yes
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default UpdateProfile;
